import React from 'react';
import ReactGA from 'react-ga';
import { AppContext } from './_context/AppContext';
import Loader from "./Common/Loader";
import MainContainer from "./Common/Style/MainContainer";
import LeftContainer from "./Common/Style/LeftContainer";
import RightContainer from "./Common/Style/RightContainer";
import Banner300x600ProveriSimptome from "./Common/Banners/Banner300x600ProveriSimptome";
import LastChanges from "./Common/LastChanges";
import Header from "./Common/Header";
import UserInfo from "./Common/UserInfo";
import ListOfTopics from "./Common/ListOfTopics";




class Home extends React.Component {
  static contextType = AppContext;
  state = {
    isLoader: false,
    listOfTopics: null,
    lastChanges: null
  }

  sendBreadcrumbs = () => {
    this.context.depositBreadcrumbs("")
    // if (this.state.listOfTopics === null) {

    // }
    if (this.state.listOfTopics.length > 0) {
      this.setState({ isLoader: false })
    }
  }

  loaderOn = () => {
    this.setState({ isLoader: true })
  }

  componentDidMount() {
          ReactGA.initialize('UA-146952850-1')
          ReactGA.pageview("FORUM"+window.location.pathname)
    const fetchTopics = fetch("https://webhooks.mongodb-stitch.com/api/client/v2.0/app/medikforum-zhmia/service/webhooks/incoming_webhook/getTopics?secret=siFRazADOHVatanmjeTemaizBaze231", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      const result = res.json()
      return result
    })

    const fetchLastChanges = fetch("https://webhooks.mongodb-stitch.com/api/client/v2.0/app/medikforum-zhmia/service/webhooks/incoming_webhook/getLastChanges?secret=koNemapoJMAmaRSIcaleEELae", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      const result = res.json()
      return result
    })

    try {
      Promise.all([fetchTopics, fetchLastChanges]).then((res) => {
        this.context.depositListOfTopics(res[0])
        this.setState({
          listOfTopics: res[0],
          lastChanges: res[1]
        })
      });
    } catch (err) {
      console.log(err)
      window.location.reload();
    }
  }


  render() {
    return (
      <>
        {this.state.isLoader &&
          <>
            <Loader />
            {this.sendBreadcrumbs()}
          </>
        }

        {!this.state.isLoader &&
          <>
            < Header />
            <UserInfo />
            <MainContainer>
              <LeftContainer>
                <ListOfTopics listOfTopics={this.state.listOfTopics} lastChanges={this.state.lastChanges} loaderOn={this.loaderOn} />
              </LeftContainer>
              <RightContainer>
                <Banner300x600ProveriSimptome />
                <LastChanges lastChanges={this.state.lastChanges} listOfTopics={this.state.listOfTopics} />
              </RightContainer>
            </MainContainer>
          </>
        }
      </>
    )
  }
}

export default Home