import React from 'react';
import { AppContext } from "../_context/AppContext";
import styled from 'styled-components';
import ChooseYourFighter from "./ChooseYourFighter";
import putGetAuthorsPosts from "../../webhooks/getSpecAuthorPosts";
import putUpdateTopicEmailNotification from "../../webhooks/putUpdateTopicEmailNotification";
import NotificationSwitch from "./NotificationSwitch";


class Settings extends React.Component {
    static contextType = AppContext;
    state = {
        topicsInvolved: null,
        topicsOn: [],
        topicsOff: [],
        topicsChanged: [],
    }


    componentDidMount() {
        const userSlug = this.context.logedUser.username
        const listOfTopics = this.context.listOfTopics
        const topicsOn = []
        const topicsOff = []
        const data = {
            username: userSlug
        }


        let userPosts = null
        let topicsInvolved = []


        try {

            putGetAuthorsPosts(data, "asiikeKEFKEOFefkekfppncvbep243.").then((response) => {
                if (response.length > 0) {

                    userPosts = response

                    userPosts.forEach((post) => {
                        const indx = topicsInvolved.indexOf(post.postTopic)
                        if (indx === -1) {
                            topicsInvolved.push(post.postTopic)
                        }
                    })


                    topicsInvolved.forEach((topicInv) => {

                        const specTopicData = listOfTopics.find((topic) => topic.topicName === topicInv)

                        const indx = specTopicData.notifyUsers.indexOf(userSlug)
                        if (indx == -1) {
                            topicsOff.push(topicInv)
                        } else {
                            topicsOn.push(topicInv)
                        }
                    })

                    this.setState({ topicsInvolved, topicsOn, topicsOff })
                }

            })
        } catch (error) {

        }

    }


    changeTopicsNotifications = (topicName, isChecked) => {
        let topicsOn = this.state.topicsOn
        let topicsOff = this.state.topicsOff
        let topicsChanged = this.state.topicsChanged

        if (isChecked) {
            const indx = topicsOff.indexOf(topicName)
            topicsOn.push(topicName)
            topicsOff.splice(indx, 1)
        } else {
            const indx = topicsOn.indexOf(topicName)
            topicsOff.push(topicName)
            topicsOn.splice(indx, 1)
        }

        const indx = topicsChanged.indexOf(topicName)
        if (indx == -1) {
            topicsChanged.push(topicName)
        }

        this.setState({
            topicsOn,
            topicsOff,
            topicsChanged
        })
    }


    mapTopicsInvolved = () => {
        const output = []
        const topicsInvolved = this.state.topicsInvolved

        if (topicsInvolved !== null) {
            topicsInvolved.forEach((topic) => {

                const indx = this.state.topicsOn.indexOf(topic)
                if (indx === -1) {
                    const element = <NotificationSwitch isChecked={false} topic={topic} key={`notificationItem${topic}`} changeTopicsNotifications={this.changeTopicsNotifications} />
                    output.push(element)
                } else {
                    const element = <NotificationSwitch isChecked={true} topic={topic} key={`notificationItem${topic}`} changeTopicsNotifications={this.changeTopicsNotifications} />
                    output.push(element)
                }

            })
        }

        return output
    }

    updateNotifications = () => {

        const topicsChanged = this.state.topicsChanged
        const topicsOn = this.state.topicsOn
        const topicsOff = this.state.topicsOff
        const listOfTopics = this.context.listOfTopics
        const username = this.context.logedUser.username


        topicsChanged.forEach((topicTitle) => {
            const indxOn = topicsOn.indexOf(topicTitle)
            const indxOff = topicsOff.indexOf(topicTitle)

            if (indxOn !== -1) {
                const specTopic = listOfTopics.filter((topic) => topic.topicName === topicTitle)[0]
                const indx = specTopic.notifyUsers.indexOf(username)
                if (indx == -1) {
                    const data = {
                        topicName: topicTitle,
                        notifyUsers: [...specTopic.notifyUsers, username]
                    }
                    putUpdateTopicEmailNotification(data, "oihoihdDKPQPCMmvuwLSPDkfjie").then((response) => {
                    })
                }

            } else if (indxOff !== -1) {
                const specTopic = listOfTopics.filter((topic) => topic.topicName === topicTitle)[0]
                const indx = specTopic.notifyUsers.indexOf(username)
                if (indx !== -1) {
                    let usersToNotify = specTopic.notifyUsers
                    usersToNotify.splice(indx, 1)
                    const data = {
                        topicName: topicTitle,
                        notifyUsers: usersToNotify
                    }
                    putUpdateTopicEmailNotification(data, "oihoihdDKPQPCMmvuwLSPDkfjie").then((response) => {
                    })
                }
            }
        })

        window.location.reload()
    }

    render() {
        return (
            <SettingsContainer>
                <Title>Podešavanja</Title>
                <SettingsWrapper>
                    <ChooseAvatarWrapper>
                        <ChooseYourFighter />
                    </ChooseAvatarWrapper>
                    <NotificationsContainer>
                        <NotificationContainerTitle>
                            Izaberi teme za koje želiš da dobijaš obaveštenja o aktivnostima
                        </NotificationContainerTitle>
                        <div>
                            {this.mapTopicsInvolved()}
                        </div>
                        <Button onClick={this.updateNotifications}>Potvrdi izbor vezan za obaveštavanja</Button>
                    </NotificationsContainer>
                </SettingsWrapper>

                <CloseIcon onClick={this.props.toggleIsSettings}>X</CloseIcon>

            </SettingsContainer>
        )
    }
}



export default Settings

const Button = styled.div`
    background-color: #7bc09c;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    font-weight:700;
    padding: 10px;
    -webkit-box-shadow: 0 0 12px 0 rgba(50,50,50,.2);
    box-shadow: 0 0 12px 0 rgba(50,50,50,.2);
    border-radius: 5px;
    &:hover{
        color: #59595b;
        background-color: #ffffff;
        cursor: pointer;
    }
`


const SettingsWrapper = styled.div`
    display: flex;
    @media (max-width: 1232px) {
       flex-direction: column;
       align-items: center;
      }
`

const NotificationsContainer = styled.div`
    width:63%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1232px) {
        width: 100%;
      }
`
const NotificationContainerTitle = styled.div`
    font-size: 18px;
    color: #ffffff;
    padding-bottom: 10px;
    border-bottom: 3px double #ffffff;
    text-align: center;
`

const ChooseAvatarWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 35%;
    border-right: 4px double #ffff;
    padding-right: 20px;
    @media (max-width: 1232px) {
        width: 66%;
        border-right: 0;
        margin-bottom: 36px;
        padding-right: 0;
      }

`
const Title = styled.h1`
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
    padding-bottom: 12px;
    border-bottom: 4px double #ffffff;
`

const SettingsContainer = styled.div`
    position: relative;
    width:  1232px;
    min-height: 666px;
    margin: 10px auto;
    padding: 20px;
    border-radius: 5px;
    color: #ffffff;
    background: linear-gradient(to right,#50b2d6,#6dbab1);
    -webkit-box-shadow: 0 0 12px 0 rgba(50,50,50,0.2);
    box-shadow: 0 0 12px 0 rgba(50,50,50,0.2);
    @media (max-width: 1232px) {
        width: 100vw;
      }
`

const CloseIcon = styled.div`
    position: absolute;
    right: 10px;
    top: 10px; 
    padding: 5px 10px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 800;
    border: 2px solid #ffffff;
    &:hover{
        cursor: pointer;
        background-color: #ffffff;
        color: #59595b;
        border-color: #59595b;
    }
`