const humanReadDateAndTime = () => {
    const months = ["januar", "februar", "mart", "april", "maj", "jun", "jul", "avgust", "septembar", "oktobar", "novembar", "decembar"]
    const date = new Date()
    const dd = date.getUTCDate();
    const mm = months[date.getUTCMonth()];
    const hh = date.getUTCHours();
    const min = date.getUTCMinutes();
    const yy = date.getUTCFullYear();


    const min00 = (function () {
        const minStringify = min.toString()
        if (minStringify.length == 1) {
            return "0" + minStringify
        } else {
            return minStringify
        }
    })();


    const outputDate = `${dd}. ${mm} ${yy} `
    const outputTime = `${hh}:${min00} č.`
    const humanDateAndTime = {
        humanDate: outputDate,
        humanTime: outputTime
    }
    return humanDateAndTime
}

export default humanReadDateAndTime;