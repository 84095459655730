import React from 'react';
import { AppContext } from "../_context/AppContext";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import putCheckUsername from "../../webhooks/checkUsername";
import putCheckEmail from "../../webhooks/checkEmail";
import postRegisterNewUser from "../../webhooks/registerNewUser";
import postRegisterNewAuthor from "../../webhooks/registerNewAuthor";
import putGetAuthor from "../../webhooks/getAuthor.js"
import humanReadDateAndTime from "../../services/humanReadDateAndTime";



class Registration extends React.Component {
    static contextType = AppContext;
    state = {
        email: "",
        username: "",
        password: "",
        passwordCheck: "",

        emailEmptyAlert: false,
        usernameEmptyAlert: false,
        passwordEmptyAlert: false,

        emailOccupied: false,
        usernameOccupied: false,
        passwordMismatch: false,

        buttonIsDisabled: false,
        isAccepted: false,
        isAcctiveAlert: false
    }

    changeEmail = (event) => {
        const data = event.target.value
        this.setState({
            email: data
        })
    }
    changeUsername = (event) => {
        const data = event.target.value
        if (data.length < 16) {
            this.setState({
                username: data
            })
        }
    }
    changePassword = (event) => {
        const data = event.target.value
        this.setState({
            password: data
        })
    }
    changePasswordCheck = (event) => {
        const data = event.target.value
        this.setState({
            passwordCheck: data
        })
    }



    checkFormData = () => {
        let checkboxReadyToGo = false
        if (!this.state.isAccepted) {
            this.setState({ isAcctiveAlert: true })
        } else {
            this.setState({ isAcctiveAlert: false })
            checkboxReadyToGo = true
        }

        let emailReadyToGo = false
        if (this.state.email === "" || !this.state.email.includes("@")) {
            this.setState({ emailEmptyAlert: true, emailOccupied: false })
            emailReadyToGo = false
        } else {
            this.setState({ emailEmptyAlert: false })
            emailReadyToGo = true
        }


        let usernameReadyToGo = false
        if (this.state.username === "") {
            this.setState({ usernameEmptyAlert: true })
            usernameReadyToGo = false
        } else {
            this.setState({ usernameEmptyAlert: false })
            usernameReadyToGo = true
        }


        let passwordReadyToGo = false
        if (this.state.password === "" || this.state.passwordCheck === "") {
            this.setState({ passwordEmptyAlert: true })
            passwordReadyToGo = false
        } else {
            this.setState({ passwordEmptyAlert: false })
            passwordReadyToGo = true
        }


        let passwordMatchReadyToGo = false
        if (this.state.password === this.state.passwordCheck) {
            this.setState({ passwordMismatch: false })
            passwordMatchReadyToGo = true
        } else {
            this.setState({ passwordMismatch: true })
            passwordMatchReadyToGo = false
        }

        if (emailReadyToGo && usernameReadyToGo && passwordReadyToGo && passwordMatchReadyToGo && checkboxReadyToGo) {
            const data = {
                email: this.state.email
            }
            putCheckEmail(data, "tajnIKLjucTZvsifrAODnekolikKARAKtera00945").then((response) => {
                if (response.length > 0) {
                    this.setState({ emailOccupied: true })
                } else {
                    this.setState({ emailOccupied: false })
                    const data = {
                        username: this.state.username
                    }
                    putCheckUsername(data, "rendOMSifrAOdnekoLIKOkaRatkera1.23").then((response) => {
                        if (response.length > 0) {
                            this.setState({ usernameOccupied: true })
                        } else {
                            this.setState({ usernameOccupied: false, buttonIsDisabled: true })
                            this.sendRegistration()
                        }
                    })
                }
            })
        }
    }

    sendRegistration = () => {
        const data = {
            username: this.state.username,
            email: this.state.email,
            password: this.state.password,
        }

        postRegisterNewUser(data, "superTajniIDUGaskiKLJucTjSIFra99385.").then((response) => {
            try {
                if (response.$undefined === true) {
                    const data = {
                        username: this.state.username,
                        joinDate: humanReadDateAndTime(),
                    }
                    postRegisterNewAuthor(data, "SifrASIFraaDostaKARAKTeraitako9.").then((response) => {
                        try {
                            if (response.$undefined === true) {
                                const data = {
                                    username: this.state.username,
                                }
                                putGetAuthor(data, "siFRAODnekioLIKOSamoKarakteRA44321").then((response) => {
                                    this.context.depositLogedUser(response[0], this.state.password)
                                })
                            } else {
                                window.location.reload()
                            }
                        } catch (err) {
                            window.location.reload();
                            console.log(err)
                        }
                        this.setState({ buttonIsDisabled: false })
                    })
                } else {
                    window.location.reload()
                }
            } catch (err) {
                window.location.reload();
                console.log(err)
            }
        })
    }

    checkIsEnter = (event) => {
        if (event.keyCode === 13) {
            event.target.blur()
            this.checkFormData()
        }
    }
    checkBlur = (event) => {
        if (event.keyCode === 13) {
            event.target.blur()
        }
    }

    handleCheckboxChange = (event) => {
        this.setState({ isAccepted: event.target.checked })
    }

    render() {
        console.log(this.state.isAccepted)
        return (
            <RegistrationContainer>
                <h2>Registracija</h2>

                <Input type="email" value={this.state.email} onChange={this.changeEmail} placeholder="Unesi svoju email adresu" onKeyDown={this.checkBlur} />
                {this.state.emailOccupied &&
                    <OccupiedNotice>Nalog registrovan ovim mejlom već postoji.<br /><br />Ukoliko ne možeš da se prisetiš lozinke,<br />klikni na "zaboravljena lozinka" u delu za PRIJAVU.</OccupiedNotice>}
                {this.state.emailEmptyAlert &&
                    <OccupiedNotice>Unesi validnu email adresu.</OccupiedNotice>}


                <Input type="text" value={this.state.username} onChange={this.changeUsername} placeholder="Unesi korisničko ime (nadimak)" onKeyDown={this.checkBlur} />
                {this.state.usernameOccupied &&
                    <OccupiedNotice>Korisničko ime je zauzeto... Probaj neko drugo.</OccupiedNotice>}
                {this.state.usernameEmptyAlert &&
                    <OccupiedNotice>Unesi nešto kao korisničko ime.</OccupiedNotice>}


                <Input type="password" value={this.state.password} onChange={this.changePassword} placeholder="Unesi lozinku za forum" onKeyDown={this.checkBlur} />
                <Input type="password" value={this.state.passwordCheck} onChange={this.changePasswordCheck} onKeyDown={this.checkIsEnter} placeholder="Ponovi lozinku za svaki slučaj" />
                {this.state.passwordMismatch &&
                    <OccupiedNotice>Lozinka nije dobro uneta, proveri je još jednom.</OccupiedNotice>}
                {this.state.passwordEmptyAlert &&
                    <OccupiedNotice>Unesi željenu lozinku a zatim je ponovi još jednom kako bi bio siguran da je dobro otkucana.</OccupiedNotice>}
                <CheckboxWrapper>
                    <Checkbox type="checkbox" checked={this.state.isAccepted} onChange={this.handleCheckboxChange} />
                    Prihvatam <Link to={`/sporazum`} target="_blank" rel="noopener noreferrer"> Sporazum o registraciji</Link>
                </CheckboxWrapper>
                {this.state.isAcctiveAlert &&
                    <OccupiedNotice>Potrebno je da prihvatite uslove korišćenja.</OccupiedNotice>}
                {!this.state.buttonIsDisabled &&
                    <NextButton onClick={this.checkFormData}>Nastavi</NextButton>}
                {this.state.buttonIsDisabled &&
                    <NextButton disabled onClick={this.checkFormData}>Nastavi</NextButton>}
                <CloseIcon onClick={this.context.closeShowForm}>X</CloseIcon>
            </RegistrationContainer>
        )
    }
}

export default Registration
const CheckboxWrapper = styled.label`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    &>a{
        margin-left: 12px;
        color: #ffffff;
        font-weight: 600;
    }

    /* @media (max-width: 1232px) {
        width: 100%;
      } */
`

const Checkbox = styled.input`
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    background-color: #ffffff;
    margin-right: 12px;
`

const RegistrationContainer = styled.div`
    position: relative;
    width:  1232px;
    margin: 10px auto;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    background: linear-gradient(to right,#50b2d6,#6dbab1);
    @media (max-width: 1232px) {
        width: 100vw;
      }
`

const Input = styled.input`
    width: 500px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-weight:600;
    font-size: 18px;
    margin-top: 24px;
    padding: 10px 20px;
    color:#59595b;
    &::placeholder {
        color:#a7aaac;
        font-size: 14px;
    }
    @media (max-width: 1232px) {
        width: 100%;
      }
`
const NextButton = styled.button`
    width: 500px;
    margin-top:48px;
    height: 40px;
    background-color: #bddfcd;
    color: #59595b;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    outline: none;
    &:hover{
        color: #fff;
        background-color: #7bc09c;
        cursor: pointer;
    }
    @media (max-width: 1232px) {
        width: 94%;
        margin: 30px auto;
      }
`

const OccupiedNotice = styled.p`
color: #ffc921;
margin-top: 10px;
`

const CloseIcon = styled.div`
    position: absolute;
    right: 10px;
    top: 10px; 
    padding: 5px 10px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 800;
    border: 2px solid #ffffff;
    &:hover{
        cursor: pointer;
        background-color: #ffffff;
        color: #59595b;
        border-color: #59595b;
    }
`