import React, { Component } from 'react';
import { AppContext } from "./screens/_context/AppContext";
import { parseCookies, setCookie, destroyCookie } from 'nookies';
import putGetAuthor from "./webhooks/getAuthor";
class AppStore extends Component {
    state = {
        breadcrumbs: "",
        logedUser: {},
        listOfTopics: null,
        isPrijava: false,
        isRegistracija: false,
        isPravila: false,
    }

    componentDidMount() {
        const cookies = parseCookies()
        if (cookies.medikforumusername) {
          const data = {
            username: cookies.medikforumusername
          }
          putGetAuthor(data, "siFRAODnekioLIKOSamoKarakteRA44321").then((response) => {
            this.setState({ logedUser: response[0] })
          })
        }
      }

    depositBreadcrumbs = (data) => {
        if (this.state.breadcrumbs !== data) {
          this.setState({
            breadcrumbs: data
          })
        }
      }
      depositListOfTopics = (data) => {
        this.setState({listOfTopics:data})
      }

      toggleShowPrijava = () => {
        this.setState({
          isPrijava: !this.state.isPrijava,
          isRegistracija: false,
          isPravila: false,
        })
      }
      toggleShowRegistracija = () => {
        window.scroll(0,0)
        this.setState({
          isPrijava: false,
          isRegistracija: !this.state.isRegistracija,
          isPravila: false,
        })
      }
      toggleShowPravila = () => {
        this.setState({
          isPrijava: false,
          isRegistracija: false,
          isPravila: !this.state.isPravila,
        })
      }
    
      closeShowForm = () => {
        this.setState({
          isPrijava: false,
          isRegistracija: false,
          isPravila: false,
        })
      }
      depositLogedUser = (data, pass) => {
        setCookie(null, 'medikforumusername', data.username)
        setCookie(null, 'medikforumpassword', pass)
        this.setState({
          logedUser: data,
          isPrijava: false,
          isRegistracija: false,
          isPravila: false,
        })
      }
      removeLogedUser = () => {
        destroyCookie(null, "medikforumusername")
        destroyCookie(null, "medikforumpassword")
        this.setState({
          logedUser: {},
        })
        window.location.reload();
      }


    render() {
        return (
            <>
                <AppContext.Provider value={{
                    ...this.state,
                    depositBreadcrumbs: this.depositBreadcrumbs,
                    depositListOfTopics: this.depositListOfTopics,
                    toggleShowPrijava: this.toggleShowPrijava,
                    toggleShowRegistracija: this.toggleShowRegistracija,
                    toggleShowPravila: this.toggleShowPravila,
                    closeShowForm: this.closeShowForm,
                    depositLogedUser: this.depositLogedUser,
                    removeLogedUser: this.removeLogedUser
                }}>

                    {this.props.children}
                </AppContext.Provider >
            </>
        )
    }
}

export default AppStore