const postCreateNewTopic = (data, qwe) => {
    return fetch(`https://webhooks.mongodb-stitch.com/api/client/v2.0/app/medikforum-zhmia/service/webhooks/incoming_webhook/postNewTopic?secret=${qwe}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            data
        })
    }).then((response) => {
        const result = response.json()
        if (response.ok) {
            return result
        } else {
            // alert(`ACHTUNG !!!
            // Došlo je do neke greške pri povezivanju sa serverom...
            // Pokušaj malko kasnije opet :-)`)
            alert (`Došlo je do greške na serveru...
                    Pokušaj ponovo kasnije.`)
        }
    })
}

export default postCreateNewTopic;