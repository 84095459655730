import React from 'react';
import { AppContext } from "../_context/AppContext";
import styled from 'styled-components';


class Rules extends React.Component {
    static contextType = AppContext;



    render() {
        return (
            <RulesContainer>

                <p><b>Pravilnik foruma</b></p>
                <br /><br />

                <p>1. Prijavljivanje i korišćenje foruma</p>
                <p>2. Postavljanje profila</p>
                <p>3. Prava korisnika</p>
                <p>4. Način pisanja</p>
                <p>5. Šta je zabranjeno</p>
                <p>6. Sankcije</p>
                <p>7. Ostale napomene</p>
                <br /><br />

                <b>Pravilnik foruma</b>

                <br /><br />

                <p><b>1. Prijavljivanje i korišćenje foruma</b></p>
                <br />
                <p>1.1. Prijavljivanje i korišćenje foruma je slobodno i besplatno.</p>
                <p>1.2. Objavljivanje poruka, odnosno aktivno učestvovanje na forumu, dostupno je samo registrovanim članovima.</p>
                <p>1.3. Prijavljivanjem i učestvovanjem na forumu prihvatate određene rizike za koje administratori foruma ne snose odgovornost poput:</p>
                <p>- neispravnost u radu foruma</p>
                <p>- mogućnost da vas treće lice kontaktira, uznemirava ili da vam naškodi na bilo koji način, ukoliko u porukama otkrijete svoje lične podatke</p>
                <p>- izrugivanje, prevara, obmanjivanje, spam.</p>
                <p>1.4. Diskusije na Medik Forumu ni u jednom slučaju ne mogu da budu zamena za medicinski savet, dijagnozu ili terapiju. Znanja i savete, stečene na forumu, članovi foruma mogu da primenjuju na sopstvenu odgovornost.</p>

                <br /><br />

                <p><b>2. Postavljanje profila</b></p>
                <br />
                <p>2.1. Definišite sebi prigodno korisničko ime (nadimak). Korisničko ime koje je provokativno, uvredljivo po druge korisnike foruma ili se na bilo koji način kosi sa nekom tačkom ovog pravilnika, biće suspendovano od strane administratora foruma.</p>
                {/* <p>2.2. Zabranjeno je korišćenje neprimerenih avatara.</p> */}

                <br /><br />

                <p><b>3. Prava korisnika</b></p>
                <br />
                <p>3.1. Svaki registrovani korisnik ima pravo da otvori novu ili da učestvuje u već otvorenoj temi.</p>
                <p>3.2. Svaki registrovani korisnik ima pravo da slobodno napiše svoje mišljenje, poštujući Pravilnik foruma.</p>

                <br /><br />

                <p><b>4. Način pisanja</b></p>
                <br />
                <p>4.1. Kako bi se izbeglo dupliranje tema, pre postavljanja pitanja ili otvaranja nove teme, obavezno koristite pretragu.</p>
                <p>4.2. Naslov teme treba da bude jasan a pitanja precizno formulisana. </p>
                {/* <p>4.3. Ukoliko želite da naglasite deo teksta, koristite opcije bold, undeline, color, italic…</p> */}
                <p>4.3. Citirajte deo poruke na koji se odnosi odgovor, ostavite samo neophodan deo teksta.</p>
                <p>4.4. Ukoliko prenosite tekst koji niste pisali, obavezno navedite ime autora ili izvora.</p>

                <br /><br />

                <p><b>5. Zabranjeno je:</b></p>
                <br />
                <p>5.1.	Postavljanje objava kojim kršite nečija zakonska, patentna, autorska ili druga prava.</p>
                <p>5.2.	 Reklamiranje usluga ili komercijalnih proizvoda ili subjekata, pojedinaca i slično. Ukoliko želite da koristite reklamni prostor na Medik Forumu, obratite se marketing službi.</p>
                <p>5.3.	 Ometanje rada uredništva foruma.</p>
                <p>5.4.	 Vređanje korisnika, izazivanje nacionalne, verske, rasne ili druge netrpeljivosti ili isticanje takvih pripadnosti.</p>
                <p>5.5.	 Objavljivanje privatnih podataka drugih članova foruma ili bilo kojih drugih osoba.</p>
                <p>5.6.	 Objavljivanje privatnih poruka bez dozvole autora poruke.</p>
                <p>5.7.	 Zloupotrebljavanje foruma ili njegovih članova.</p>
                <p>5.8.	 Deljenje korisničkog imena i lozinke sa drugim osobama, kao i otvaranje više od jednog korisničkog naloga.</p>

                <br /><br />

                <p><b>6. Sankcije</b></p>
                <br />
                <p>6.1. Korisnik koji ne poštuje Pravilnik foruma biće upozoren od strane administratora. Ukoliko korisnik nakon upozorenja nastavi sa neprimerenim ponašanjem, biće mu zabranjen pristup forumu.</p>

                <br /><br />

                <p><b>7. Ostalo</b></p>
                <br />
                <p>7.1. Administratori foruma zadržavaju pravo da:</p>
                <p>- izvrše ispravke ili bilo koje druge promene na forumu</p>
                <p>- reorganizuju informacije na sistemu</p>
                <p>- uklone zastarele poruke</p>
                <p>- ukinu forum u bilo kom trenutku, privremeno ili stalno</p>
                <p>- promene ili ograniče korišćenje foruma ili bilo kog njegovog dela</p>
                <p>- daju bilo kom registrovanom članu zvanje urednika ili moderatora, kao i da ukinu ovo zvanje</p>
                <p>- da promene ovaj pravilnik u bilo kom trenutku, iz  bilo kog razloga, uz objavljivanje</p>
                <p>7.2. Potpunu odgovornost (moralnu, pravnu i krivičnu) za sadržaj objavljene poruke snosi isključivo njen autor, čije je korisničko ime označeno pored poruke. </p>
                <p>7.3. Bez odobrenja administratora, zabranjeno je umnožavanje i kopiranje sadržaja foruma. </p>
                <p>Želimo vam prijatan boravak na Medik Forumu.</p>

                <br /><br />
                <HorizontalDoubleLine />
                <p><b>Politika privatnosti i postupanje sa podacima o ličnosti</b></p>
                <br />
                <p>1. Forum Medik.rs od korisnika prilikom registracije na forum zahteva unos sledećih podataka:</p>
                <p>-   Željeno korisničko ime</p>
                <p>-   E-mail adresu</p>
                <br/>
                <p>Navedeni podaci se koriste na sledeći način: E-mail i lozinka koriste se za prijavu na forum, e-mail adresa za primanje automatskih obaveštenja i obnovu zaboravljene lozinke. Takođe, vrši se i evidencija vremena registracije, vreme i broj poslatih odgovora i broj otvorenih tema.</p>
                <p>Osim navedenih podataka, kako bi bila omogućena funkcionalnost kod korišćenja foruma prikupljaju se podaci o postavljenim komentarima/postovima registrovanih korisnika. Podaci se koriste isključivo u svrhu registracije korisnika i korišćenja funkcionalnosti komentarisanja i oni se mogu promeniti ili izbrisati na zahtev poslat na e-mail adresu <a href="mailto:forumadmin@medik.rs">forumadmin@medik.rs</a>. </p>
                <p>Za informacije o opštoj politici privatnosti i postupanje sa podacima o ličnosti svih posetilaca portala Medik.rs pogledajte sledeći dokument <a href="https://www.medik.rs/politika-privatnosti" target="_blank" rel="noopener noreferrer">https://www.medik.rs/politika-privatnosti.</a></p>

                <br/><br/>
                <p><b>Sigurnost</b></p>
                <br/>
                <p>Medik.rs ulaže napore kako bi se obezbedila sigurnost ličnih podataka, kao i usklađenost sa pravilima GDPR. Vaši podaci savesno su zaštićeni od gubitka, uništenja, manipulacije, neovlašćenog pristupa i izdavanja.</p>
                <p>Navedeni podaci, izuzev e-mail adrese, vidljivi su svim posetiocima foruma. E-mail adresa vidljiva je samo administratorima.</p>
                <br/>
                <p><b>Podaci koje korisnici sami unose:</b></p>
                <p>Odgovori dati od strane korisnika ostaju trajno zapisani u bazu podataka, osim u slučaju kada administratori procene da se radi o neprilagođenom sadržaju, spamu, zabranjenom sadržaju, pornografiji i sl. U tom slučaju sporni sadržaj se uklanja.</p>
                <p>O sadržaju odgovora, što uključuje bilo koji oblik ličnih podataka, predatim od strane korisnika odlučuju i odgovaraju sami korisnici koji predaju sadržaj.</p>
                <br/>
                <p><b>Brisanje podataka</b></p>
                <br/>
                <p>Ukoliko odluče da više ne budu korisnici foruma, kao aktivni članovi, korisnici mogu u bilo kom trenutku zatražiti brisanje korisničkog naloga.</p>
                <p>Procedura brisanja korisničkog naloga je sledeća:</p>
                <p>1.  Poslati e-mail administratoru foruma na <a href="mailto:forumadmin@medik.rs">forumadmin@medik.rs.</a></p>
                <p>2.  Podaci koji se brišu su svi podaci osim korisničkog imena i postova koji su citirani.</p>
                <p>Korisničko ime ostaje trajno zapisano iz razloga omogućavanja pravilnog pregleda baze podataka.</p>




                <CloseIcon onClick={this.context.closeShowForm}>X</CloseIcon>
            </RulesContainer>
        )
    }
}

export default Rules


const RulesContainer = styled.div`
    position: relative;
    width:  1232px;
    margin: 10px auto;
    padding: 40px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 18px;
    background: linear-gradient(to right,#50b2d6,#6dbab1);
    -webkit-box-shadow: 0 0 12px 0 rgba(50,50,50,0.2);
    box-shadow: 0 0 12px 0 rgba(50,50,50,0.2);
    @media (max-width: 1232px) {
        width: 100vw;
        font-size: 14px;
    }
    &>p{
        margin-bottom: 10px;
        &>a{
            font-weight:800;
            color: #ffffff;
        }
    }
    `
const HorizontalDoubleLine = styled.div`
    width: 100%;
    border-bottom: 4px double #ffffff;
    margin-bottom: 24px;
    margin-top: 24px;
`

const CloseIcon = styled.div`
    position: absolute;
    right: 10px;
    top: 10px; 
    padding: 5px 10px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 800;
    border: 2px solid #ffffff;
    &:hover{
        cursor: pointer;
        background-color: #ffffff;
        color: #59595b;
        border-color: #59595b;
    }
`