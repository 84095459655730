import React from 'react';
import {AppContext} from "../_context/AppContext";
import putCheckUsernameAndPassword from "../../webhooks/checkUsernameAndPassword";
import putGetAuthor from "../../webhooks/getAuthor";
import putCheckEmail from "../../webhooks/checkEmail";
import putSendForgotPasswordEmail from "../../webhooks/sendForgotPasswordEmail";
import styled from 'styled-components';



class SignIn extends React.Component {
    static contextType = AppContext;
    state = {
        email: "",
        password: "",

        emailEmptyAlert: false,
        passwordEmptyAlert: false,

        noRegisteredEmail: false,
        wrongPassword: false,

        showPassEmailField: false,
        forgotPass: "",
        forgotPassEmptyAlert: false,
        forgotPassNoRegisteredEmail: false,
        forgotPassSentToEmail: false
    }

    changeEmail = (event) => {
        const data = event.target.value
        this.setState({
            email: data
        })
    }
    changePassword = (event) => {
        const data = event.target.value
        this.setState({
            password: data
        })
    }

    checkFormData = () => {
        let emailReadyToGo = false
        if (this.state.email === "" || !this.state.email.includes("@")) {
            this.setState({ emailEmptyAlert: true })
            emailReadyToGo = false
        } else {
            this.setState({ emailEmptyAlert: false })
            emailReadyToGo = true
        }

        let passwordReadyToGo = false
        if (this.state.password === "" || this.state.passwordCheck === "") {
            this.setState({ passwordEmptyAlert: true })
            passwordReadyToGo = false
        } else {
            this.setState({ passwordEmptyAlert: false })
            passwordReadyToGo = true
        }


        if (emailReadyToGo && passwordReadyToGo) {
            const data = {
                email: this.state.email
            }
            putCheckEmail(data, "tajnIKLjucTZvsifrAODnekolikKARAKtera00945").then((response) => {
                if (response.length === 0) {
                    this.setState({ noRegisteredEmail: true })
                } else {
                    this.setState({ noRegisteredEmail: false })
                    this.logIn()
                }
            })
        }

    }


    logIn = () => {
        const data = {
            email: this.state.email,
            password: this.state.password
        }

        putCheckUsernameAndPassword(data, "neVALJapretHDOnassifrAPADApromenom2012123.").then((response) => {
            if (response.length > 0) {
                this.setState({ wrongPassword: false })
                const data = {
                    username: response[0].username
                }
                putGetAuthor(data, "siFRAODnekioLIKOSamoKarakteRA44321").then((response) => {
                    this.context.depositLogedUser(response[0], this.state.password)
                })
            } else {
                this.setState({ wrongPassword: true })
            }
        })
    }

    checkIsEnter = (event) => {
        if (event.keyCode === 13) {
            event.target.blur()
            this.checkFormData()
        }
    }
    checkBlur = (event) => {
        if (event.keyCode === 13) {
            event.target.blur()
        }
    }

    showForgotPass = () => {
        this.setState({ showPassEmailField: true })
    }

    changeForgetPass = (event) => {
        const data = event.target.value
        this.setState({
            forgotPass: data
        })
    }
    checkIsEnterForPass = (event) => {
        if (event.keyCode === 13) {
            event.target.blur()
            this.checkForgetPass()
        }
    }
    checkForgetPass = () => {
        let emailReadyToGo = false
        if (this.state.forgotPass === "" || !this.state.forgotPass.includes("@")) {
            this.setState({ forgotPassEmptyAlert: true })
            emailReadyToGo = false
        } else {
            this.setState({ forgotPassEmptyAlert: false })
            emailReadyToGo = true
        }

        if (emailReadyToGo) {
            const data = {
                email: this.state.forgotPass
            }
            putCheckEmail(data, "tajnIKLjucTZvsifrAODnekolikKARAKtera00945").then((response) => {
                if (response.length === 0) {
                    this.setState({ forgotPassNoRegisteredEmail: true })
                } else {
                    const data = {
                        email: response[0].email,
                        username: response[0].username,
                        password: response[0].password,
                    }
                    putSendForgotPasswordEmail(data, "maRsicnemaPOjmaIjosnekaSlovaiTako").then((response) => {
                        this.setState({ forgotPassSentToEmail: true, forgotPassNoRegisteredEmail: false })
                    })
                }
            })
        }
    }


    render() {
        return (
            <SignInContainer>
                <Input type="email" value={this.state.email} onChange={this.changeEmail} onKeyDown={this.checkBlur} placeholder="Unesi svoju email adresu" />
                {this.state.emailEmptyAlert && <OccupiedNotice>Unesi email adresu korišćenu pri registraciji.</OccupiedNotice>}
                {this.state.noRegisteredEmail && <OccupiedNotice>Ne postoji korisnik registrovan sa unetom email adresom.</OccupiedNotice>}
                <Input type="password" value={this.state.password} onChange={this.changePassword} onKeyDown={this.checkIsEnter} placeholder="Unesi lozinku za forum" />
                {this.state.passwordEmptyAlert && <OccupiedNotice>Unesi lozinku korišćenu pri registraciji.</OccupiedNotice>}
                {this.state.wrongPassword && <OccupiedNotice>Pogrešna lozinka.</OccupiedNotice>}
                <NextButton onClick={this.checkFormData}>Prijava</NextButton>


                <ForgetPassword onClick={this.showForgotPass}>Zaboravljena lozinka?</ForgetPassword>
                {this.state.showPassEmailField &&
                    <>
                        <Input type="email" value={this.state.forgotPass} onChange={this.changeForgetPass} onKeyDown={this.checkIsEnterForPass} placeholder="Unesi email adresu koja je korišćena prilikom registracije" />
                        {this.state.forgotPassEmptyAlert &&
                            <OccupiedNotice>Unesi email adresu korišćenu pri registraciji.</OccupiedNotice>}
                        {this.state.forgotPassNoRegisteredEmail &&
                            <OccupiedNotice>Ne postoji korisnik registrovan sa unetom email adresom.</OccupiedNotice>}
                        {this.state.forgotPassSentToEmail &&
                            <OccupiedNotice>Mejl sa kredencijalima je poslat na tvoju adresu...</OccupiedNotice>}
                        <NextButton onClick={this.checkForgetPass}>Pošalji za lozinku</NextButton>
                    </>
                }
                <CloseIcon onClick={this.context.closeShowForm}>X</CloseIcon>
            </SignInContainer>
        )
    }
}

export default SignIn

const SignInContainer = styled.div`
    position: relative;
    width:  1232px;
    margin: 10px auto;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to right,#50b2d6,#6dbab1);
    @media (max-width: 1232px) {
        width: 100vw;
        padding-top: 50px;
      }
`
const Input = styled.input`
    width: 500px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-weight:600;
    font-size: 18px;
    margin-top: 24px;
    padding: 10px 20px;
    color: #59595b;
    &::placeholder {
        color:#a7aaac;
        font-size: 14px;
    }
    @media (max-width: 1232px) {
        width: 100%;
      }
`
const NextButton = styled.button`
    width: 500px;
    margin-top:30px;
    height: 40px;
    background-color: #bddfcd;
    color: #59595b;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    outline: none;
    &:hover{
        color: #fff;
        background-color: #7bc09c;
        cursor: pointer;
    }
    @media (max-width: 1232px) {
        width: 94%;
        margin: 30px auto;
      }
`

const ForgetPassword = styled.p`
    color: #ffffff;
    margin-top: 40px;
    transition: transform 0.2s;
    &:hover{
        color: #59595b;
        cursor: pointer;
        transform: scale(1.1);
        font-weight: 600;
    }
    @media (max-width: 1232px) {
        margin-top: 24px;
      }
`

const OccupiedNotice = styled.p`
    color: #ffc921;
    margin-top: 10px;
`

const CloseIcon = styled.div`
    position: absolute;
    right: 10px;
    top: 10px; 
    padding: 5px 10px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 800;
    border: 2px solid #ffffff;
    &:hover{
        cursor: pointer;
        background-color: #ffffff;
        color: #59595b;
        border-color: #59595b;
    }
`