import React from 'react';
import styled from 'styled-components';
import {AppContext} from "../_context/AppContext";
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import putChangeAvatar from "../../webhooks/updateChangeAvatar";
import aDefault from "./imgs/avatar-1577909_640.png";
import a0 from "./imgs/avatars/user.png";
import a1 from "./imgs/avatars/user-1.png";
import a2 from "./imgs/avatars/user-2.png";
import a3 from "./imgs/avatars/user-3.png";
import a4 from "./imgs/avatars/user-4.png";
import a5 from "./imgs/avatars/user-5.png";
import a6 from "./imgs/avatars/user-6.png";
import a7 from "./imgs/avatars/user-7.png";
import a8 from "./imgs/avatars/user-8.png";
import a9 from "./imgs/avatars/user-9.png";
import a10 from "./imgs/avatars/user-10.png";
import a11 from "./imgs/avatars/user-11.png";
import a12 from "./imgs/avatars/user-12.png";
import a13 from "./imgs/avatars/user-13.png";
import a14 from "./imgs/avatars/user-14.png";
import a15 from "./imgs/avatars/user-15.png";
import a16 from "./imgs/avatars/user-16.png";
import a17 from "./imgs/avatars/user-17.png";
import a18 from "./imgs/avatars/user-18.png";
import a19 from "./imgs/avatars/user-19.png";
import a20 from "./imgs/avatars/user-20.png";
import a21 from "./imgs/avatars/user-21.png";
import a22 from "./imgs/avatars/user-22.png";
import a23 from "./imgs/avatars/user-23.png";
import a24 from "./imgs/avatars/user-24.png";
import a25 from "./imgs/avatars/user-25.png";
import a26 from "./imgs/avatars/user-26.png";
import a27 from "./imgs/avatars/user-27.png";
import a28 from "./imgs/avatars/user-28.png";
import a29 from "./imgs/avatars/user-29.png";
import a30 from "./imgs/avatars/user-30.png";
import a31 from "./imgs/avatars/user-31.png";
import a32 from "./imgs/avatars/user-32.png";
import a33 from "./imgs/avatars/user-33.png";
import a34 from "./imgs/avatars/user-34.png";
import a35 from "./imgs/avatars/user-35.png";
import a36 from "./imgs/avatars/user-36.png";
import a37 from "./imgs/avatars/user-37.png";
import a38 from "./imgs/avatars/user-38.png";
import a39 from "./imgs/avatars/user-39.png";


class ChooseYourFighter extends React.Component {
    static contextType = AppContext;
    state = {
        selectedAvatar: null,
        selectedAvatarElement: <AvatarDefault />
    }



    componentDidMount() {
        this.setState({
            selectedAvatar: parseInt(this.context.logedUser.avatar, 10)
        })
    }

    changeAvatarPlus = () => {
        this.setState({ selectedAvatar: this.state.selectedAvatar + 1 })
        if (this.state.selectedAvatar == 40) {
            this.setState({ selectedAvatar: 0 })
        }
    }

    changeAvatarMinus = () => {
        this.setState({ selectedAvatar: this.state.selectedAvatar - 1 })
        if (this.state.selectedAvatar == 0) {
            this.setState({ selectedAvatar: 40 })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedAvatar !== this.state.selectedAvatar) {

            const selectedAvatarElement = this.state.selectedAvatar.toString();
            switch (selectedAvatarElement) {
                case "40":
                    this.setState({ selectedAvatarElement: <AvatarDefault /> })
                    break;
                case "0":
                    this.setState({ selectedAvatarElement: <Avatar0 /> })
                    break;
                case "1":
                    this.setState({ selectedAvatarElement: <Avatar1 /> })
                    break;
                case "2":
                    this.setState({ selectedAvatarElement: <Avatar2 /> })
                    break;
                case "3":
                    this.setState({ selectedAvatarElement: <Avatar3 /> })
                    break;
                case "4":
                    this.setState({ selectedAvatarElement: <Avatar4 /> })
                    break;
                case "5":
                    this.setState({ selectedAvatarElement: <Avatar5 /> })
                    break;
                case "6":
                    this.setState({ selectedAvatarElement: <Avatar6 /> })
                    break;
                case "7":
                    this.setState({ selectedAvatarElement: <Avatar7 /> })
                    break;
                case "8":
                    this.setState({ selectedAvatarElement: <Avatar8 /> })
                    break;
                case "9":
                    this.setState({ selectedAvatarElement: <Avatar9 /> })
                    break;
                case "10":
                    this.setState({ selectedAvatarElement: <Avatar10 /> })
                    break;
                case "11":
                    this.setState({ selectedAvatarElement: <Avatar11 /> })
                    break;
                case "12":
                    this.setState({ selectedAvatarElement: <Avatar12 /> })
                    break;
                case "13":
                    this.setState({ selectedAvatarElement: <Avatar13 /> })
                    break;
                case "14":
                    this.setState({ selectedAvatarElement: <Avatar14 /> })
                    break;
                case "15":
                    this.setState({ selectedAvatarElement: <Avatar15 /> })
                    break;
                case "16":
                    this.setState({ selectedAvatarElement: <Avatar16 /> })
                    break;
                case "17":
                    this.setState({ selectedAvatarElement: <Avatar17 /> })
                    break;
                case "18":
                    this.setState({ selectedAvatarElement: <Avatar18 /> })
                    break;
                case "19":
                    this.setState({ selectedAvatarElement: <Avatar19 /> })
                    break;
                case "20":
                    this.setState({ selectedAvatarElement: <Avatar20 /> })
                    break;
                case "21":
                    this.setState({ selectedAvatarElement: <Avatar21 /> })
                    break;
                case "22":
                    this.setState({ selectedAvatarElement: <Avatar22 /> })
                    break;
                case "23":
                    this.setState({ selectedAvatarElement: <Avatar23 /> })
                    break;
                case "24":
                    this.setState({ selectedAvatarElement: <Avatar24 /> })
                    break;
                case "25":
                    this.setState({ selectedAvatarElement: <Avatar25 /> })
                    break;
                case "26":
                    this.setState({ selectedAvatarElement: <Avatar26 /> })
                    break;
                case "27":
                    this.setState({ selectedAvatarElement: <Avatar27 /> })
                    break;
                case "28":
                    this.setState({ selectedAvatarElement: <Avatar28 /> })
                    break;
                case "29":
                    this.setState({ selectedAvatarElement: <Avatar29 /> })
                    break;
                case "30":
                    this.setState({ selectedAvatarElement: <Avatar30 /> })
                    break;
                case "31":
                    this.setState({ selectedAvatarElement: <Avatar31 /> })
                    break;
                case "32":
                    this.setState({ selectedAvatarElement: <Avatar32 /> })
                    break;
                case "33":
                    this.setState({ selectedAvatarElement: <Avatar33 /> })
                    break;
                case "34":
                    this.setState({ selectedAvatarElement: <Avatar34 /> })
                    break;
                case "35":
                    this.setState({ selectedAvatarElement: <Avatar35 /> })
                    break;
                case "36":
                    this.setState({ selectedAvatarElement: <Avatar36 /> })
                    break;
                case "37":
                    this.setState({ selectedAvatarElement: <Avatar37 /> })
                    break;
                case "38":
                    this.setState({ selectedAvatarElement: <Avatar38 /> })
                    break;
                case "39":
                    this.setState({ selectedAvatarElement: <Avatar39 /> })
                    break;
    
                default:
                    this.setState({ selectedAvatarElement: <AvatarDefault /> })
            }


        }
    }

    updateAvatar = () => {
        const data = {
            username: this.context.logedUser.username,
            avatar: this.state.selectedAvatar.toString()
        }
        putChangeAvatar(data, "kljbjEVKEVPOEV{:E{lvepoekv").then(() =>{
            window.location.reload()
        })
    }
    

    render() {
        return (
            <ChooseYourFighterWrapper>
                {this.state.selectedAvatarElement}
                <ArrowsWrapper>
                    <ArrowLeft color="#ffffff" size={96} onClick={this.changeAvatarMinus} />
                    <ArrowRight color="#ffffff" size={96} onClick={this.changeAvatarPlus} />
                </ArrowsWrapper>
                <Button onClick={this.updateAvatar}>Potvrdi izbor avatara</Button>
            </ChooseYourFighterWrapper>
        )
    }
}

export default ChooseYourFighter

const ChooseYourFighterWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`


const Button = styled.div`
    background-color: #7bc09c;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    font-weight:700;
    padding: 10px;
    -webkit-box-shadow: 0 0 12px 0 rgba(50,50,50,.2);
    box-shadow: 0 0 12px 0 rgba(50,50,50,.2);
    margin-left: 5px;
    border-radius: 5px;
    &:hover{
        color: #59595b;
        background-color: #ffffff;
        cursor: pointer;
    }
`


const ArrowsWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &>svg:hover{
        transform: scale(1.1);
        cursor: pointer;
    }
`

const AvatarDefault = styled.img.attrs({
    src: aDefault
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar0 = styled.img.attrs({
    src: a0
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar1 = styled.img.attrs({
    src: a1
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`


const Avatar2 = styled.img.attrs({
    src: a2
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar3 = styled.img.attrs({
    src: a3
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar4 = styled.img.attrs({
    src: a4
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar5 = styled.img.attrs({
    src: a5
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`


const Avatar6 = styled.img.attrs({
    src: a6
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`


const Avatar7 = styled.img.attrs({
    src: a7
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`


const Avatar8 = styled.img.attrs({
    src: a8
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`


const Avatar9 = styled.img.attrs({
    src: a9
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`


const Avatar10 = styled.img.attrs({
    src: a10
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`


const Avatar11 = styled.img.attrs({
    src: a11
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`


const Avatar12 = styled.img.attrs({
    src: a12
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`


const Avatar13 = styled.img.attrs({
    src: a13
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`


const Avatar14 = styled.img.attrs({
    src: a14
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar15 = styled.img.attrs({
    src: a15
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar16 = styled.img.attrs({
    src: a16
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar17 = styled.img.attrs({
    src: a17
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar18 = styled.img.attrs({
    src: a18
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar19 = styled.img.attrs({
    src: a19
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar20 = styled.img.attrs({
    src: a20
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar21 = styled.img.attrs({
    src: a21
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar22 = styled.img.attrs({
    src: a22
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar23 = styled.img.attrs({
    src: a23
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar24 = styled.img.attrs({
    src: a24
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar25 = styled.img.attrs({
    src: a25
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar26 = styled.img.attrs({
    src: a26
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar27 = styled.img.attrs({
    src: a27
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar28 = styled.img.attrs({
    src: a28
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar29 = styled.img.attrs({
    src: a29
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar30 = styled.img.attrs({
    src: a30
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar31 = styled.img.attrs({
    src: a31
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar32 = styled.img.attrs({
    src: a32
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar33 = styled.img.attrs({
    src: a33
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar34 = styled.img.attrs({
    src: a34
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar35 = styled.img.attrs({
    src: a35
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar36 = styled.img.attrs({
    src: a36
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar37 = styled.img.attrs({
    src: a37
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar38 = styled.img.attrs({
    src: a38
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`

const Avatar39 = styled.img.attrs({
    src: a39
})
    `
width: 100%;
height: 412px;
border-radius: 50%;
background-color:#ffffff;
@media (max-width: 1232px) {
        height: 200px;
      }
`