import styled from 'styled-components';


const LeftContainer = styled.section `
        width: calc(100% - 310px);
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1232px) {
            width: 100vw;
          }
`;

export default LeftContainer