import React from 'react';
import { AppContext } from "../_context/AppContext";
import { Link } from "react-router-dom";
import Text from "react-format-text";
import styled from 'styled-components';
import { ReplyAllFill, EnvelopeFill } from 'react-bootstrap-icons';
import MyAvatar from "./MyAvatar";


class SinglePost extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props)
  }
  state = {
  }


  replayIt = () => {
    const quotedAuthor = this.props.post.author
    const quotedContent = this.props.post.content
    this.props.depositReplayData(quotedAuthor, quotedContent)
  }

  checkRank = (data) => {
    const noPosts = data
    if (noPosts < 5) {
      return "Novi član"
    } else if (noPosts > 4) {
      return "Član"
    } else if (noPosts > 25) {
      return "Aktivni član"
    } else if (noPosts > 50) {
      return "Ugledni član"
    } else if (noPosts > 100) {
      return "Počasni član"
    } else if (noPosts > 500) {
      return "Istaknuti član"
    } else if (noPosts > 1000) {
      return "Poznati član"
    }
  }

  render() {
    return (
      <SinglePostContainer>
        <AvatarWrapper>
          <MyAvatar avatarNo={this.props.author.avatar} />
          <Link to={`/ucesnik/${this.props.post.author.split(" ").join("-")}`} target="_blank" rel="noopener noreferrer">
            {this.props.post.author}
          </Link>
          <AvatarInfo><span>Rang:</span><span>{this.checkRank(this.props.author.posts.length)}</span></AvatarInfo>
          <AvatarInfo><EnvelopeFill color="#59595b" width="24px" height="24px" />{this.props.author.posts.length}</AvatarInfo>

        </AvatarWrapper>
        <PostContentWrapper>
          <DateNtimeWrapper>
            <DateNtime><span>Objavljeno:</span><span>{this.props.post.dateNtime.humanDate} - {this.props.post.dateNtime.humanTime}</span></DateNtime>
            <HashNumber>#{this.props.count}</HashNumber>
          </DateNtimeWrapper>
          {this.props.post.quotedAuthor !== null &&
            <QuoteWrapper>
              <Text>{this.props.post.quotedContent}</Text>
              <QuotedAuthor>
                {this.props.post.quotedAuthor}
              </QuotedAuthor>
            </QuoteWrapper>
          }
          <Text>{this.props.post.content}</Text>
          {this.context.logedUser.username &&
            <ReplayIconWrapper onClick={this.replayIt}>
              <ReplyAllFill color="#50b2d6" width="36px" height="36px" />
            </ReplayIconWrapper>
          }
        </PostContentWrapper>
      </SinglePostContainer>
    )
  }
}



export default SinglePost

const HashNumber = styled.div`
  color: #59595b;
`

const QuotedAuthor = styled.div`
    border-top: 4px double #59595b;
    text-align: right;
    @media (max-width: 1232px) {
      margin-top: 24px;
      }
`

const QuoteWrapper = styled.div`
    background-color: #e9e8ea;
    margin: 24px;
    color: #59595b;
    padding: 12px;
    font-style: italic;
    @media (max-width: 1232px) {
      width: 98%;
      margin: 15px auto;
      }
`

const ReplayIconWrapper = styled.div`
  display: inline-block;
  margin-left: auto;
  & > svg:hover{
    cursor: pointer;
    transform: scale(1.2);
  }
`

const SinglePostContainer = styled.div`
  width: 100%;
  padding:20px;
  padding-left: 0;
  /* flex-direction: column; */
  display: flex;
        /* align-items: center; */
  justify-content: space-between;
  background-color: #ffffff;
  border: 3px solid #50b2d6;
  border-radius: 15px;
  margin-bottom: 7.9px;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
      -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
      box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    &:hover{
       /* cursor: pointer; */
      -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.25);
      -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.25);
      box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.25);
    }
        @media (max-width: 1232px) {
        flex-direction: column;
        align-items: center;
        padding-left: 20px;
        }
`

const AvatarWrapper = styled.div`
background-color:#f5f5f5;
border-radius: 15px;
  width: 15%;
  height: 190px;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & > a {
  color: #59595b;
  margin-top: 12px;
  font-weight: 600;
      white-space: nowrap;
    &:hover{
      transform: scale(1.1);
      /* width:100vw; */
      text-align: center;
    }
    @media (max-width: 1232px) {
      text-align: center;
      }
  }
  @media (max-width: 1232px) {
      width: 66%;
      min-height: 275px;
      margin: 15px auto;
      }
`
const AvatarInfo = styled.div`
  margin-top: 15px;
  color: #59595b;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const PostContentWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1232px) {
      width: 100%;
      }
`
const DateNtime = styled.p`
  font-size: 14px;
  @media (max-width: 1232px) {
      /* width: 100%; */
      display: flex;
      flex-direction: column;
      /* align-items: center; */
      }
`
const DateNtimeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #6dbab1;
  margin-bottom: 20px;
  @media (max-width: 1232px) {
      margin-bottom: 15px;
      }
`