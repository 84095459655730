import React from 'react';
import styled from 'styled-components';
import aDefault from "./imgs/avatar-1577909_640.png";
import a0 from "./imgs/avatars/user.png";
import a1 from "./imgs/avatars/user-1.png";
import a2 from "./imgs/avatars/user-2.png";
import a3 from "./imgs/avatars/user-3.png";
import a4 from "./imgs/avatars/user-4.png";
import a5 from "./imgs/avatars/user-5.png";
import a6 from "./imgs/avatars/user-6.png";
import a7 from "./imgs/avatars/user-7.png";
import a8 from "./imgs/avatars/user-8.png";
import a9 from "./imgs/avatars/user-9.png";
import a10 from "./imgs/avatars/user-10.png";
import a11 from "./imgs/avatars/user-11.png";
import a12 from "./imgs/avatars/user-12.png";
import a13 from "./imgs/avatars/user-13.png";
import a14 from "./imgs/avatars/user-14.png";
import a15 from "./imgs/avatars/user-15.png";
import a16 from "./imgs/avatars/user-16.png";
import a17 from "./imgs/avatars/user-17.png";
import a18 from "./imgs/avatars/user-18.png";
import a19 from "./imgs/avatars/user-19.png";
import a20 from "./imgs/avatars/user-20.png";
import a21 from "./imgs/avatars/user-21.png";
import a22 from "./imgs/avatars/user-22.png";
import a23 from "./imgs/avatars/user-23.png";
import a24 from "./imgs/avatars/user-24.png";
import a25 from "./imgs/avatars/user-25.png";
import a26 from "./imgs/avatars/user-26.png";
import a27 from "./imgs/avatars/user-27.png";
import a28 from "./imgs/avatars/user-28.png";
import a29 from "./imgs/avatars/user-29.png";
import a30 from "./imgs/avatars/user-30.png";
import a31 from "./imgs/avatars/user-31.png";
import a32 from "./imgs/avatars/user-32.png";
import a33 from "./imgs/avatars/user-33.png";
import a34 from "./imgs/avatars/user-34.png";
import a35 from "./imgs/avatars/user-35.png";
import a36 from "./imgs/avatars/user-36.png";
import a37 from "./imgs/avatars/user-37.png";
import a38 from "./imgs/avatars/user-38.png";
import a39 from "./imgs/avatars/user-39.png";


class MyAvatar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            authorsAvatar: <AvatarDefault />
        }
    }



    componentDidMount() {
        const authorsAvatar = this.props.avatarNo.toString();
        switch (authorsAvatar) {
            case "40":
                this.setState({ authorsAvatar: <AvatarDefault /> })
                break;
            case "0":
                this.setState({ authorsAvatar: <Avatar0 /> })
                break;
            case "1":
                this.setState({ authorsAvatar: <Avatar1 /> })
                break;
            case "2":
                this.setState({ authorsAvatar: <Avatar2 /> })
                break;
            case "3":
                this.setState({ authorsAvatar: <Avatar3 /> })
                break;
            case "4":
                this.setState({ authorsAvatar: <Avatar4 /> })
                break;
            case "5":
                this.setState({ authorsAvatar: <Avatar5 /> })
                break;
            case "6":
                this.setState({ authorsAvatar: <Avatar6 /> })
                break;
            case "7":
                this.setState({ authorsAvatar: <Avatar7 /> })
                break;
            case "8":
                this.setState({ authorsAvatar: <Avatar8 /> })
                break;
            case "9":
                this.setState({ authorsAvatar: <Avatar9 /> })
                break;
            case "10":
                this.setState({ authorsAvatar: <Avatar10 /> })
                break;
            case "11":
                this.setState({ authorsAvatar: <Avatar11 /> })
                break;
            case "12":
                this.setState({ authorsAvatar: <Avatar12 /> })
                break;
            case "13":
                this.setState({ authorsAvatar: <Avatar13 /> })
                break;
            case "14":
                this.setState({ authorsAvatar: <Avatar14 /> })
                break;
            case "15":
                this.setState({ authorsAvatar: <Avatar15 /> })
                break;
            case "16":
                this.setState({ authorsAvatar: <Avatar16 /> })
                break;
            case "17":
                this.setState({ authorsAvatar: <Avatar17 /> })
                break;
            case "18":
                this.setState({ authorsAvatar: <Avatar18 /> })
                break;
            case "19":
                this.setState({ authorsAvatar: <Avatar19 /> })
                break;
            case "20":
                this.setState({ authorsAvatar: <Avatar20 /> })
                break;
            case "21":
                this.setState({ authorsAvatar: <Avatar21 /> })
                break;
            case "22":
                this.setState({ authorsAvatar: <Avatar22 /> })
                break;
            case "23":
                this.setState({ authorsAvatar: <Avatar23 /> })
                break;
            case "24":
                this.setState({ authorsAvatar: <Avatar24 /> })
                break;
            case "25":
                this.setState({ authorsAvatar: <Avatar25 /> })
                break;
            case "26":
                this.setState({ authorsAvatar: <Avatar26 /> })
                break;
            case "27":
                this.setState({ authorsAvatar: <Avatar27 /> })
                break;
            case "28":
                this.setState({ authorsAvatar: <Avatar28 /> })
                break;
            case "29":
                this.setState({ authorsAvatar: <Avatar29 /> })
                break;
            case "30":
                this.setState({ authorsAvatar: <Avatar30 /> })
                break;
            case "31":
                this.setState({ authorsAvatar: <Avatar31 /> })
                break;
            case "32":
                this.setState({ authorsAvatar: <Avatar32 /> })
                break;
            case "33":
                this.setState({ authorsAvatar: <Avatar33 /> })
                break;
            case "34":
                this.setState({ authorsAvatar: <Avatar34 /> })
                break;
            case "35":
                this.setState({ authorsAvatar: <Avatar35 /> })
                break;
            case "36":
                this.setState({ authorsAvatar: <Avatar36 /> })
                break;
            case "37":
                this.setState({ authorsAvatar: <Avatar37 /> })
                break;
            case "38":
                this.setState({ authorsAvatar: <Avatar38 /> })
                break;
            case "39":
                this.setState({ authorsAvatar: <Avatar39 /> })
                break;

            default:
                this.setState({ authorsAvatar: <AvatarDefault /> })
        }
    }

    render() {
        return (this.state.authorsAvatar)
    }
}

export default MyAvatar


// const AvatarDefault = styled.img.attrs({
//     src: 'static/images/avatar-1577909_640.png'
// })
//     `
// width: 50%;
// height: auto;
// border-radius: 50%;
// background-color: #ffffff;
// `
const AvatarDefault = styled.img.attrs({
    src: aDefault
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar0 = styled.img.attrs({
    src: a0
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar1 = styled.img.attrs({
    src: a1
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`


const Avatar2 = styled.img.attrs({
    src: a2
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar3 = styled.img.attrs({
    src: a3
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar4 = styled.img.attrs({
    src: a4
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar5 = styled.img.attrs({
    src: a5
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`


const Avatar6 = styled.img.attrs({
    src: a6
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`


const Avatar7 = styled.img.attrs({
    src: a7
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`


const Avatar8 = styled.img.attrs({
    src: a8
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`


const Avatar9 = styled.img.attrs({
    src: a9
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`


const Avatar10 = styled.img.attrs({
    src: a10
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`


const Avatar11 = styled.img.attrs({
    src: a11
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`


const Avatar12 = styled.img.attrs({
    src: a12
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`


const Avatar13 = styled.img.attrs({
    src: a13
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`


const Avatar14 = styled.img.attrs({
    src: a14
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar15 = styled.img.attrs({
    src: a15
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar16 = styled.img.attrs({
    src: a16
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar17 = styled.img.attrs({
    src: a17
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar18 = styled.img.attrs({
    src: a18
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar19 = styled.img.attrs({
    src: a19
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar20 = styled.img.attrs({
    src: a20
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar21 = styled.img.attrs({
    src: a21
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar22 = styled.img.attrs({
    src: a22
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar23 = styled.img.attrs({
    src: a23
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar24 = styled.img.attrs({
    src: a24
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar25 = styled.img.attrs({
    src: a25
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar26 = styled.img.attrs({
    src: a26
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar27 = styled.img.attrs({
    src: a27
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar28 = styled.img.attrs({
    src: a28
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar29 = styled.img.attrs({
    src: a29
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar30 = styled.img.attrs({
    src: a30
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar31 = styled.img.attrs({
    src: a31
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar32 = styled.img.attrs({
    src: a32
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar33 = styled.img.attrs({
    src: a33
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar34 = styled.img.attrs({
    src: a34
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar35 = styled.img.attrs({
    src: a35
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar36 = styled.img.attrs({
    src: a36
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar37 = styled.img.attrs({
    src: a37
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar38 = styled.img.attrs({
    src: a38
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`

const Avatar39 = styled.img.attrs({
    src: a39
})
    `
width: 50%;
height: auto;
border-radius: 50%;
background-color: #ffffff;
`