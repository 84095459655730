import React from 'react';
import styled from 'styled-components';
import banner from "./bit-banner-1024x190.png";

class BannerBIT790x200 extends React.Component {


  render() {
    return (
      <a href="https://www.bgit.rs/?utm_source=medik-forum&utm_medium=medik-forum&utm_campaign=medik-forum" target="_blank">
        <BitBanner />
      </a>
    )
  }
}

export default BannerBIT790x200


const BitBanner = styled.img.attrs({
  src: banner
})`
  max-width: 790px;
  height: 200px;
  margin: 24px auto;
  align-self: center;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.25);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.25);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.25);
  `