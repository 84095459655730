import React from 'react';
import ReactGA from 'react-ga';
import { AppContext } from './_context/AppContext';
import styled from 'styled-components';
import Loader from "./Common/Loader";
import MainContainer from "./Common/Style/MainContainer";
import LeftContainer from "./Common/Style/LeftContainer";
import RightContainer from "./Common/Style/RightContainer";
import Banner300x600ProveriSimptome from "./Common/Banners/Banner300x600ProveriSimptome";
import Header from "./Common/Header";
import UserInfo from "./Common/UserInfo";




class GDPR extends React.Component {
  static contextType = AppContext;
  state = {
    isLoader: false,
    listOfTopics: null,
    lastChanges: null
  }

  sendBreadcrumbs = () => {
    this.context.depositBreadcrumbs("")
    if (this.state.listOfTopics.length > 0) {
      this.setState({ isLoader: false })
    }
  }

  loaderOn = () => {
    this.setState({ isLoader: true })
  }

  componentDidMount() {
    ReactGA.initialize('UA-146952850-1')
    ReactGA.pageview("FORUM" + window.location.pathname)
  }


  render() {
    return (
      <>
        {this.state.isLoader &&
          <>
            <Loader />
            {this.sendBreadcrumbs()}
          </>
        }

        {!this.state.isLoader &&
          <>
            < Header isGdpr={true} />
            <UserInfo />
            <MainContainer>
              <LeftContainer>
                <RulesContainer>
                  <h1>Sporazum o registraciji</h1> <br /><br />
                  <p>Sve teme i odgovori su isključivo lično mišljenje njihovih autora, stoga se administratori
                  ovog foruma ne mogu smatrati odgovornim. Administratori ovog foruma će se potruditi da
                  uklone svaki neprikladan odgovor ili temu. Odgovornost za sadržaj poslatih poruka snosi
                  autor poruke.</p>

                  <p>Prihvatajući uslove registracije, izjašnjavate se da ste prihvatili Pravilnik foruma. Ukoliko
                  se slažete sa ovim pravilima, možete nastaviti s registracijom. Zahvaljujemo Vam se na
                  pažnji i želimo Vam ugodan boravak na Medik Forumu!</p>
                </RulesContainer>


              </LeftContainer>
              <RightContainer>
                <Banner300x600ProveriSimptome />
              </RightContainer>
            </MainContainer>
          </>
        }
      </>
    )
  }
}

export default GDPR


const RulesContainer = styled.div`
    width:  100%;
    margin: 10px auto;
    padding: 40px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 18px;
    background: linear-gradient(to right,#50b2d6,#6dbab1);
    -webkit-box-shadow: 0 0 12px 0 rgba(50,50,50,0.2);
    box-shadow: 0 0 12px 0 rgba(50,50,50,0.2);
    @media (max-width: 1232px) {
        width: 100vw;
        font-size: 14px;
    }
    &>p{
        margin-bottom: 10px;
        &>a{
            font-weight:800;
            color: #ffffff;
        }
    }
    `