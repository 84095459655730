import React from 'react';
import { AppContext } from "../_context/AppContext";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { EnvelopeFill } from 'react-bootstrap-icons';
import TextareaAutosize from 'react-textarea-autosize';
import postCreateNewTopic from "../../webhooks/createNewTopic";
import putLastChangedTopics from "../../webhooks/updateLastTopics";
import postCreateNewPost from "../../webhooks/createNewPost";
import getCreatedPostID from "../../webhooks/getCreatedPostID";
import updateTopicWithNewPost from "../../webhooks/updateTopicWithNewPost";
import updateAuthorWithNewPost from "../../webhooks/updateAuthorsWithNewPost";
import humanReadDateAndTime from "../../services/humanReadDateAndTime";
import logo from "./imgs/favicon.ico";
import BannerWideProveriSimptopme from "./Banners/BannerWideProveriSimptopme";;

class ListOfTopics extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props)
    }
    state = {
        isNewTopic: false,
        newTopic: "",
        duplicateNewTopics: [],
        isHyphen: false,
        isIntroPost: false,
        introPostContent: "",
        introPostLengthIssue: false,
        windowWidth: null
    }

    componentDidMount = () => {
        this.setState({ windowWidth: window.innerWidth })

    }

    mapTopics = () => {
        if (this.props.listOfTopics !== null) {

            let topics = this.props.listOfTopics

            topics.sort(function (a, b) {
                return b.posts.length - a.posts.length
            })


            const output = topics.map((topic, i) => {
                return <TopicItemW100 key={`tema-${i}`}>
                    <Link to={`/tema/${topic.topicName.split(" ").join("-")}`}>
                        <TopicItemContainer>
                            <TopicTitleWrapper>
                                <TopicLogo />
                                <TopicTitle>
                                    {topic.topicName}
                                </TopicTitle>
                            </TopicTitleWrapper>

                            <DataViewWrapper>
                                <IconWrapper>
                                    <EnvelopeFill color="#50b2d6" width="24px" height="24px" />
                                    <Info>{topic.posts.length}</Info>
                                </IconWrapper>
                            </DataViewWrapper>
                        </TopicItemContainer>
                    </Link>
                </TopicItemW100>

            })
            return output
        }

    }

    showAddNewTopic = () => {
        this.setState({ isNewTopic: true })
    }

    changeNewTopic = (event) => {
        const data = event.target.value
        if (data.includes("-")) {
            this.setState({ isHyphen: true })
        } else {
            this.setState({ newTopic: data, isHyphen: false })
            this.checkNewTopic(data)
        }
    }

    checkIsEnter = (event) => {
        if (event.keyCode === 13) {
            event.target.blur()
        }
    }

    checkNewTopic = (data) => {
        if (data.length > 2) {
            const newTopic = data.toLowerCase()
            const outputTopics = []
            this.props.listOfTopics.forEach((topic) => {
                if (topic.topicName.toLowerCase().includes(newTopic)) {
                    outputTopics.push(topic)
                }
            })
            this.setState({ duplicateNewTopics: outputTopics })
        } else {
            this.setState({ duplicateNewTopics: [] })
        }
    }


    mapDuplicateNewTopics = () => {
        const output = this.state.duplicateNewTopics.map((topic, i) => {
            return <DuplicateTopicContainer key={`duplikattema-${i}`}>
                <Link to={`/tema/${topic.topicName.split(" ").join("-")}`} target="_blank" rel="noopener noreferrer">
                    <TopicItemContainer>
                        <TopicTitleWrapper>
                            <TopicLogo />
                            <TopicTitle>
                                {topic.topicName}
                            </TopicTitle>
                        </TopicTitleWrapper>
                        <DataViewWrapper>
                            <IconWrapper>
                                <EnvelopeFill color="#50b2d6" width="24px" height="24px" />
                                <Info>{topic.posts.length}</Info>
                            </IconWrapper>
                        </DataViewWrapper>
                    </TopicItemContainer>
                </Link>
            </DuplicateTopicContainer>
        })
        return output
    }

    sendNewTopic = () => {

        const creationTime = humanReadDateAndTime()
        const data = {
            topicName: this.state.newTopic,
            creator: this.context.logedUser.username,
            parentTopic: null,
            created: creationTime,
            childTopic: [],
            posts: [],
            notifyUsers: [this.context.logedUser.username]
        }

        postCreateNewTopic(data, "duGACKAsifraSaKaratkteroima").then((response) => {
            if (response.$undefined === true) {
                try {
                    const data = [
                        this.state.newTopic,
                        this.props.lastChanges[0].lastFive[0],
                        this.props.lastChanges[0].lastFive[1],
                        this.props.lastChanges[0].lastFive[2],
                        this.props.lastChanges[0].lastFive[3],
                        this.props.lastChanges[0].lastFive[4],
                    ]
                    putLastChangedTopics(data, "nekaSifraOdnebasTOLIKOmnogOKARAKTera123").then(() => {
                        this.setState({ isIntroPost: true })
                    })
                } catch (err) {
                    console.log(err)
                    window.location.reload()
                }
            }
        })
    }

    depositIntroPostContent = (event) => {
        const data = event.target.value

        if (this.state.introPostLengthIssue && data.length > 23) {
            this.setState({ introPostLengthIssue: false })
        }
        this.setState({ introPostContent: data })
    }


    sendNewPost = () => {
        this.props.loaderOn()
        const newPostContent = this.state.introPostContent

        if (newPostContent.length > 23) {

            const creationTime = humanReadDateAndTime()
            const data = {
                content: newPostContent,
                author: this.context.logedUser.username,
                dateNtime: creationTime,
                postTopic: this.state.newTopic,
                quotedAuthor: null,
                quotedContent: null
            }
            postCreateNewPost(data, "sifRASifRAAOdKaraktERADOstaiTAko739494").then((response) => {
                try {
                    if (response.$undefined === true) {
                        this.setState({ isIntroPost: false })
                        getCreatedPostID(data.content, "sifrAAsifrtrokwfgojwfWEGF").then((response) => {
                            try {
                                if (response.length > 0) {
                                    const data = {
                                        postTopic: this.state.newTopic,
                                        postID: response[0]._id,
                                        username: this.context.logedUser.username
                                    }
                                    updateTopicWithNewPost(data, "poiwnefiewWEFWKFWEPFwefkwpoefkjw").then((response) => {
                                        try {
                                            if (response.$undefined === true) {
                                                updateAuthorWithNewPost(data, "hiuhonownevfWEFWEfgwefEWFGWEF").then((response) => {
                                                    //   try {
                                                    //     if (response.$undefined === true) {
                                                    //       const data = [
                                                    //         activeTopic + "=post=" + newPostContent,
                                                    //         props.lastChanges[1].lastFive[0],
                                                    //         props.lastChanges[1].lastFive[1],
                                                    //         props.lastChanges[1].lastFive[2],
                                                    //         props.lastChanges[1].lastFive[3],
                                                    //         props.lastChanges[1].lastFive[4],
                                                    //       ]
                                                    //       putLastChangedPosts(data, "opkjwgWGPWERGKpowkge0WGF3").then((response) => {
                                                    //         window.location.reload()
                                                    //       })
                                                    //     }
                                                    //   } catch (err) {
                                                    //     console.log(err)
                                                    //     window.location.reload()
                                                    //   }

                                                    window.location.reload()
                                                })
                                            } else {
                                                window.location.reload()
                                            }
                                        } catch (err) {
                                            console.log(err)
                                            window.location.reload()
                                        }
                                    })
                                } else {
                                    window.location.reload()
                                }
                            } catch (err) {
                                console.log(err)
                                window.location.reload()
                            }
                        })
                    } else {
                        window.location.reload()
                    }
                } catch (err) {
                    console.log(err)
                    window.location.reload()
                }
            })
        } else {
            this.setState({ introPostLengthIssue: true })
        }
    }

    render() {
        return (
            <>
                {this.mapTopics()}
                {!this.context.isRegistracija && !this.context.isPrijava &&
                    <>

                        {this.state.isNewTopic && !this.state.isIntroPost &&
                            <NewTopicWrapper>
                                <Input autoFocus type="text" value={this.state.newTopic} onChange={this.changeNewTopic} placeholder="Na koju temu želiš pokrenuti raspravu?" onKeyDown={this.checkIsEnter} />
                                {this.state.isHyphen &&
                                    <OccupiedNotice>Povlaku (znak "-") nije moguće staviti u naslovu teme!</OccupiedNotice>}
                                {this.state.duplicateNewTopics.length !== 0 &&
                                    <>
                                        <OccupiedNotice>Možda je neka od već postojećih tema upravo ona koju tražiš?</OccupiedNotice>
                                        {this.mapDuplicateNewTopics()}
                                        <NewTopicButton onClick={this.sendNewTopic}>Postavi temu svejedno</NewTopicButton>

                                    </>}
                                {this.state.duplicateNewTopics.length === 0 && this.state.newTopic.length !== 0 && !this.state.isIntroPost &&
                                    <NewTopicButton onClick={this.sendNewTopic}>Postavi temu</NewTopicButton>}
                            </NewTopicWrapper>}

                        {this.context.logedUser.username && !this.state.isNewTopic && !this.state.isIntroPost &&
                            <NewTopicButton onClick={this.showAddNewTopic}>Dodaj novu temu</NewTopicButton>}
                        {!this.context.logedUser.username &&
                            <NewTopicButton onClick={this.context.toggleShowRegistracija}>Želiš da započneš novu temu?<br /><br />Neophodno je da se prvo<br />prijaviš ili registruješ.</NewTopicButton>}
                    </>}

                {this.state.isIntroPost &&
                    <>
                        <NewPost>
                            <TextareaAutosize autoFocus value={this.state.introPostContent} onChange={this.depositIntroPostContent} placeholder={`Upiši uvodni komentar za upravo kreiranu temu: ${this.state.newTopic}`} />
                        </NewPost>
                        {this.state.introPostLengthIssue &&
                            <OccupiedNotice>Da bi uvodni komentar bio uspešno kreiran, potrebno je da sadrži barem nekoliko reči :-)</OccupiedNotice>
                        }
                        <OkCancelWrapper>
                            <OkNewPostButton onClick={this.sendNewPost}>Objavi</OkNewPostButton>
                        </OkCancelWrapper>
                    </>}
                {this.state.windowWidth > 1243 &&
          <BannerWideProveriSimptopme />
        }
            </>
        )
    }
}

export default ListOfTopics

const TopicItemW100 = styled.div`
width: 98%;
`


const TopicItemContainer = styled.div`
width: 100%;
padding: 10px 20px;
display: flex;
justify-content: space-between;
align-items: center;
background-color: #ffffff;
border: 3px solid #50b2d6;
border-radius: 15px;
margin-bottom: 7.9px;
-webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);
  &:hover{
    cursor: pointer;
    -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.5);
}
@media (max-width: 1232px) {
       /* flex-direction: column; */
      }
`

const DuplicateTopicContainer = styled.div`
width: 100%;
`

const TopicTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
  @media (max-width: 1232px) {
       width: 100%;
      }
  
`

const TopicLogo = styled.img.attrs({
    src: logo
})`
  width: 25px;
  margin-right: 10px;
`
const TopicTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #59595b;
  @media (max-width: 1232px) {
        font-size: 18px;
      }
`

const DataViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Info = styled.p`
  color: #59595b;
  font-size: 16px;
  margin-left: 5px;
  font-weight: 600;
`

const NewTopicButton = styled.div`
  width: 50%;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  background: linear-gradient(to right,#6dbab1, #50b2d6);
  border: 3px solid #50b2d6;
  border-radius: 15px;
  margin: 0 auto;
  margin-top: 45px;
  text-align: center;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
      -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
      box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    &:hover{
      cursor: pointer;
      -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.5);
      -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.5);
      box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.5);
}
      @media (max-width: 1232px) {
       width: 80%;
       margin: 24px auto;
      }
`
const Input = styled.input`
    width: 500px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-weight:600;
    font-size: 18px;
    padding: 10px 20px;
    color: #59595b;
    &::placeholder {
        color:#a7aaac;
        font-size: 14px;
    }
    @media (max-width: 1232px) {
        width: 100%;
        &::placeholder {
        font-size: 12px;
    }
      }
`
const NewTopicWrapper = styled.div`
    border-radius:10px;
    border: double 7px #50b2d6;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    @media (max-width: 1232px) {
        margin-bottom: 24px;
    }
`

const OccupiedNotice = styled.p`
    color: #ffc921;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    padding: 5px 10px;
    background-color: #ffffff;
`

const NewPost = styled.div`
    margin-top: 50px;
    width: 100%;
    & > textarea{
      width: 100%;
      min-height: 240px;
      background-color: #ffffff;
      resize: none;
      border: none;
      border-radius: 5px;
      outline: none;
      font-weight:600;
      font-size: 18px;
      padding: 10px 20px;
      color: #59595b;
        &::placeholder {
            color:#a7aaac;
            font-size: 18px;
        }
        @media (max-width: 1232px) {
        width: 96%;
        margin: 0 auto;
    }
}
`

const OkCancelWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
`
const CancelNewPostButton = styled.div`
  width: 50%;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  background: linear-gradient(90deg, #ffffff 0%, #AA3939 50%, #AA3939 100%);
  border-radius: 15px;
  margin: 0 auto;
  margin-top: 45px;
  text-align: center;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
      -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
      box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    &:hover{
      cursor: pointer;
      -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.5);
      -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.5);
      box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.5);
}
`

const OkNewPostButton = styled.div`
  width: 50%;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  background: linear-gradient(90deg, #6dbab1 0%, #6dbab1 65%, #ffffff 100%);
  border-radius: 15px;
  margin: 0 auto;
  margin-top: 45px;
  text-align: center;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
      -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
      box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    &:hover{
      cursor: pointer;
      -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.5);
      -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.5);
      box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.5);
}
`