import React from 'react';
import {AppContext} from "../_context/AppContext";
import {Link} from "react-router-dom";
import styled from 'styled-components';
import SignIn from "./SignIn";
import Registration from "./Registration";
import Rules from "./Rules";
import Settings from "./Settings";



class UserInfo extends React.Component {
    static contextType = AppContext;
    state = {
        isSettings: false
    }

    checkRank = () => {
        const noPosts = this.context.logedUser.posts.length
        if (noPosts < 5) {
            return "Novi član"
        } else if (noPosts > 4) {
            return "Član"
        } else if (noPosts > 25) {
            return "Aktivni član"
        } else if (noPosts > 50) {
            return "Ugledni član"
        } else if (noPosts > 100) {
            return "Počasni član"
        } else if (noPosts > 500) {
            return "Istaknuti član"
        } else if (noPosts > 1000) {
            return "Poznati član"
        }
    }

    checkBreadcrumbs = () => {
        let breadcrumbs = this.context.breadcrumbs
        const topicQuery = "/tema/"
        const output = breadcrumbs.replace(topicQuery, "")
        if (output) {
            return <Link to={`/tema/${output}`}>
                    <WhiteLink>{output.split("-").join(" ")} ></WhiteLink>
                </Link>
        }
    }

    toggleIsSettings = () => {
        this.setState({ isSettings: !this.state.isSettings })
    }




    render() {
        return (
            <>
                <UserInfoContainer>
                    <BreadcrumbsTitle>
                            <Link to={"/"}>
                                <WhiteLink>Početna ></WhiteLink>
                            </Link>
                        {this.checkBreadcrumbs()}
                    </BreadcrumbsTitle>
                    {!this.context.logedUser.username &&
                        <ButtonsWrapper>
                            <Button isActive={this.context.isPrijava} onClick={this.context.toggleShowPrijava}>Prijava</Button>
                            <Button isActive={this.context.isRegistracija} onClick={this.context.toggleShowRegistracija}>Registracija</Button>
                            <Button isActive={this.context.isPravila} onClick={this.context.toggleShowPravila}>Pravila foruma</Button>
                        </ButtonsWrapper>
                    }
                    {this.context.logedUser.username &&
                        <UserInfoWrapper>
                            <UsernameWrapper>
                                <div>Korisničko ime: {this.context.logedUser.username}</div>
                                <div>Datum registracije: {this.context.logedUser.joinDate.humanDate.split(".").join(" ")}</div>
                            </UsernameWrapper>
                            <RangWrapper>
                                <div>Broj objava: {this.context.logedUser.posts.length}</div>
                                <div>Rang: {this.checkRank()}</div>
                            </RangWrapper>
                            <SettingsButtonsWrapper>
                                <Button onClick={this.toggleIsSettings}>Podešavanja</Button>
                                <Button onClick={this.context.removeLogedUser}>Odjavi me</Button>
                            </SettingsButtonsWrapper>
                        </UserInfoWrapper>
                    }
                </UserInfoContainer>
                {this.context.isPrijava &&
                    <SignIn />
                }
                {this.context.isRegistracija &&
                    <Registration />
                }
                {this.context.isPravila &&
                    <Rules />
                }

                {this.state.isSettings &&
                    <Settings toggleIsSettings={this.toggleIsSettings} />}
            </>
        )
    }
}

export default UserInfo

const SettingsButtonsWrapper = styled.div`
    display: flex;
    @media (max-width: 1232px) {
        flex-direction: column;
      }
`

const UserInfoContainer = styled.div`
    width:  1232px;
    min-height: 99px;
    margin: 10px auto;
    padding: 17px 20px 13px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to right,#6dbab1,#50b2d6);
    -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    @media (max-width: 1232px) {
        width: 100vw;
        padding-left: 12px;
      }
`
const BreadcrumbsTitle = styled.div`
    display: flex;
    width: 100%;
    display: flex;
    height: 45%;
    @media (max-width: 1232px) {
        width: 100%;
        flex-direction: column;
        height: auto;
      }
    `
const WhiteLink = styled.div`
      color: #ffffff;
      font-weight: 500;
      margin-left: 10px;
      &:hover{
          cursor: pointer;
          font-weight: 800;
      }
      @media (max-width: 1232px) {
        width: 100%;
        margin-bottom: 12px;
        margin-left: 0;
      }
    `
const ButtonsWrapper = styled.div`
    width: 100%;
    height: 45%;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 1232px) {
        margin-top: 20px;
        flex-direction: column;
      }
`

const Button = styled.div`
    text-align: center;
    color: #59595b;
    font-size: 16px;
    font-weight:700;
    padding: 6px 16px;
    background-color: ${props => props.isActive ? "#7bc09c" : "#bddfcd"};
    -webkit-box-shadow: 0 0 12px 0 rgba(50,50,50,.2);
    box-shadow: 0 0 12px 0 rgba(50,50,50,.2);
    margin-left: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    &:hover{
        color: #fff;
        background-color: #7bc09c;
        cursor: pointer;
    }
    @media (max-width: 1232px) {
        text-align:center;
        margin-top: 20px;
      }
`

const UserInfoWrapper = styled.div`
    width: 100%;
    height: 45%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1232px) {
        /* margin-top: 20px; */
        flex-direction: column;
      }
`

const UsernameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: #59595b;
    @media (max-width: 1232px) {
        margin-bottom: 20px;
      }
`
const RangWrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: #59595b;
    @media (max-width: 1232px) {
        flex-direction: row;
        justify-content: space-between;
      }
`
