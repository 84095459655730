import React from 'react';
import { Route, Switch } from 'react-router-dom'
import Homepage from "./screens/Homepage"
import Tema from "./screens/Tema";
import Ucesnik from "./screens/Ucesnik";
import GDPR from "./screens/GDPR";
import "./App.css";

function App() {
  return (
      <Switch>
        <Route exact path='/' component={Homepage} />
        <Route exact path='/tema/:naslov' component={Tema} />
        <Route exact path='/ucesnik/:username' component={Ucesnik} />
        <Route exact path='/sporazum' component={GDPR} />
        
      </Switch>
  );
}

export default App;