import styled from 'styled-components';


const MainContainer = styled.main `
        width:  1232px;
        margin: 10px auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;


        @media (max-width: 1232px) {
            width: 100vw;
          }

          @media (max-width: 992px) {
            flex-direction: column;
          }
`;

export default MainContainer