import styled from 'styled-components';


const RightContainer = styled.section `
        width: 300px;

        @media (max-width: 1232px) {
            width: 100vw;
          }
`;

export default RightContainer