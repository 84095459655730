import React from 'react';
import { AppContext } from './_context/AppContext';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { Link, withRouter } from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize';
import { EnvelopeFill, ClockFill } from 'react-bootstrap-icons';
import Text from "react-format-text";
import MainContainer from "./Common/Style/MainContainer";
import LeftContainer from "./Common/Style/LeftContainer";
import RightContainer from "./Common/Style/RightContainer";
import humanReadDateAndTime from "../services/humanReadDateAndTime";
import Loader from "./Common/Loader";
import Header from "./Common/Header";
import UserInfo from "./Common/UserInfo";
import SinglePost from "./Common/SinglePost";
import Banner300x600ProveriSimptome from "./Common/Banners/Banner300x600ProveriSimptome";
import LastChanges from "./Common/LastChanges";
import postCreateNewPost from "../webhooks/createNewPost";
import getCreatedPostID from "../webhooks/getCreatedPostID";
import updateTopicWithNewPost from "../webhooks/updateTopicWithNewPost";
import updateAuthorWithNewPost from "../webhooks/updateAuthorsWithNewPost";
import putGetPostsForTopic from "../webhooks/getPostsForTopic";
import putLastChangedPosts from "../webhooks/updateLastPosts";
import putNotifyAuthorAboutQuot from "../webhooks/putNotifyAuthorAboutQuot";
import putNotifyUsersAboutNewPost from "../webhooks/putNotifyUsersWithNewPost";
import BannerWideProveriSimptopme from "./Common/Banners/BannerWideProveriSimptopme";
import BannerBIT300x250 from "./Common/Banners/BannerBIT300x250";
import BannerBIT790x200 from "./Common/Banners/BannerBIT790x200";

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton,
} from "react-share";




class Tema extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props)
        this.state = {
            listOfTopics: null,
            lastChanges: null,
            allAuthors: null,
            topicStateData: null,
            isNewPost: false,
            newPostContent: "",
            activeTopic: "",
            postsData: null,
            postLengthIssue: false,
            isLoader: true,
            newQuotedAuthor: null,
            newQuotedContent: null,
            path: "",
            windowWidth: null
        }
    }


    componentDidMount() {
        ReactGA.initialize('UA-146952850-1')
        ReactGA.pageview("FORUM" + window.location.pathname + window.location.search)

        const fetchTopics = fetch("https://webhooks.mongodb-stitch.com/api/client/v2.0/app/medikforum-zhmia/service/webhooks/incoming_webhook/getTopics?secret=siFRazADOHVatanmjeTemaizBaze231", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            const result = res.json()
            return result
        })

        const fetchLastChanges = fetch("https://webhooks.mongodb-stitch.com/api/client/v2.0/app/medikforum-zhmia/service/webhooks/incoming_webhook/getLastChanges?secret=koNemapoJMAmaRSIcaleEELae", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            const result = res.json()
            return result
        })

        const fetchAllAuthors = fetch("https://webhooks.mongodb-stitch.com/api/client/v2.0/app/medikforum-zhmia/service/webhooks/incoming_webhook/getAllAuthors?secret=jihWCKPWKPVWkwpokjvpwkv'WKPKVw", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            const result = res.json()
            return result
        })

        try {
            Promise.all([fetchTopics, fetchLastChanges, fetchAllAuthors]).then((res) => {
                this.context.depositListOfTopics(res[0])
                this.checkWidth()
                this.setState({
                    listOfTopics: res[0],
                    lastChanges: res[1],
                    allAuthors: res[2]
                })
            });
        } catch (err) {
            console.log(err)
            window.location.reload();
        }

    }



    checkWidth = () => {

        this.setState({
            windowWidth: window.innerWidth
        })

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.listOfTopics == null && this.state.listOfTopics !== null) {
            this.getTopicData()
            this.context.depositListOfTopics(this.state.listOfTopics)
        }
    }

    loaderOn = () => {
        this.setState({
            isLoader: true
        })
    }

    getTopicData = () => {

        const topicQuery = this.props.match.params.naslov
        let topicSlug = ""

        if (topicQuery.includes("-")) {
            this.context.depositBreadcrumbs(`${topicQuery}`)
            topicSlug = topicQuery.split("-").join(" ")

        } else {
            this.context.depositBreadcrumbs(`${topicQuery.split(" ").join("-")}`)
            topicSlug = topicQuery
        }


        if (this.state.activeTopic === "") {
            this.setState({
                activeTopic: topicSlug
            })
        }

        const topicData = this.state.listOfTopics.filter((topic) => {
            return topic.topicName.includes(topicSlug)
        })

        if (this.state.topicStateData === null) {
            this.setState({
                topicStateData: topicData[0]
            })
        }

        const data = {
            topic: topicSlug
        }


        try {
            putGetPostsForTopic(data, "siFRAAFsifRAopALALACArta").then((response) => {
                this.setState({
                    postsData: response,
                    isLoader: false
                })
            })
        } catch (err) {
            console.log(err)
            window.location.reload()
        }

        this.setState({
            path: this.props.location.pathname
        })

    }

    showAddNewPost = () => {
        // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        this.setState({ isNewPost: true })
    }

    depositNewPostContent = (event) => {
        const data = event.target.value
        const charLengthCheck = 2000 - data.length

        if (this.state.postLengthIssue && data.length > 23) {
            this.setState({
                postLengthIssue: false
            })
        }
        if (charLengthCheck >= 0){
            this.setState({
                newPostContent: data
            })

        }
    }

    cancelNewPost = () => {
        this.setState({
            newQuotedAuthor: null,
            newQuotedContent: null,
            isNewPost: false
        })
    }

    notifyQuotedAuthor = (data) => {
        putNotifyAuthorAboutQuot(data, "sifrAAsiFRAaa").then((res) => {
        })
    }

     sendNewPost = () => {
        if (this.state.newPostContent.length > 23) {
            this.loaderOn()
            const creationTime = humanReadDateAndTime()
            const data = {
                content: this.state.newPostContent,
                author: this.context.logedUser.username,
                dateNtime: creationTime,
                postTopic: this.state.activeTopic,
                quotedAuthor: this.state.newQuotedAuthor,
                quotedContent: this.state.newQuotedContent,
            }
            postCreateNewPost(data, "sifRASifRAAOdKaraktERADOstaiTAko739494").then((response) => {
                try {
                    if (response.$undefined === true) {
                        this.setState({
                            isNewPost: false
                        })
                        getCreatedPostID(data.content, "sifrAAsifrtrokwfgojwfWEGF").then((response) => {
                            try {
                                if (response.length > 0) {
                                    const data = {
                                        postTopic: this.state.activeTopic,
                                        postID: response[0]._id,
                                        username: this.context.logedUser.username
                                    }
                                    updateTopicWithNewPost(data, "poiwnefiewWEFWKFWEPFwefkwpoefkjw").then((response) => {
                                        try {
                                            if (response.$undefined === true) {
                                                updateAuthorWithNewPost(data, "hiuhonownevfWEFWEfgwefEWFGWEF").then((response) => {
                                                    try {
                                                        if (response.$undefined === true) {
                                                            const data = [
                                                                this.state.activeTopic + "=post=" + this.state.newPostContent,
                                                                this.state.lastChanges[1].lastFive[0],
                                                                this.state.lastChanges[1].lastFive[1],
                                                                this.state.lastChanges[1].lastFive[2],
                                                                this.state.lastChanges[1].lastFive[3],
                                                                this.state.lastChanges[1].lastFive[4],
                                                            ]
                                                            putLastChangedPosts(data, "opkjwgWGPWERGKpowkge0WGF3").then((response) => {
                                                                if (this.state.newQuotedAuthor !== null) {
                                                                    const data = {
                                                                        quotedTopic: this.state.activeTopic,
                                                                        quotedAuthor: this.state.newQuotedAuthor,
                                                                        quotedContent: this.state.newQuotedContent,
                                                                        username: this.context.logedUser.username,
                                                                    }
                                                                    putNotifyAuthorAboutQuot(data, "sifrAAsiFRAaa").then((res) => {
                                                                        console.log(res)
                                                                    })
                                                                }
                                                                try {
                                                                    if (response.$undefined === true) {
                                                                        let notifyUsersData = this.state.topicStateData.notifyUsers
                                                                        const data = {
                                                                            notifyUsers: notifyUsersData,
                                                                            postTopic: this.state.activeTopic,
                                                                            content: this.state.newPostContent,
                                                                            author: this.context.logedUser.username,
                                                                        }
                                                                        putNotifyUsersAboutNewPost(data, "oiwbherogWEGEWPOGWJEKGWEPg").then((response) => {
                                                                            console.log(response)
                                                                            window.location.reload()
                                                                        })
                                                                    } else {
                                                                        window.location.reload()
                                                                    }
                                                                } catch (err) {
                                                                    console.log(err)
                                                                    window.location.reload()
                                                                }


                                                            })
                                                        } else {
                                                            window.location.reload()
                                                        }
                                                    } catch (err) {
                                                        console.log(err)
                                                        window.location.reload()
                                                    }
                                                })
                                            } else {
                                                window.location.reload()
                                            }
                                        } catch (err) {
                                            console.log(err)
                                            window.location.reload()
                                        }
                                    })
                                } else {
                                    window.location.reload()
                                }
                            } catch (err) {
                                console.log(err)
                                window.location.reload()
                            }
                        })
                    } else {
                        window.location.reload()
                    }
                } catch (err) {
                    console.log(err)
                    window.location.reload()
                }
            })
        } else {
            this.setState({
                postLengthIssue: true
            })
        }
    }


     depositReplayData = (quotedAuthor, quotedContent) => {
         this.setState({
             newQuotedAuthor: quotedAuthor,
             newQuotedContent: quotedContent,
             isNewPost: true
         })
    }


     mapPosts = () => {
        const output = []

        if (this.state.postsData !== null) {
            this.state.postsData.forEach((post, i) => {
                const author = this.state.allAuthors.find(author => author.username == post.author);
                output.push(
                    <SinglePost author={author} post={post} key={"singlePost" + i} count={i} depositReplayData={this.depositReplayData} />
                )
                if (this.state.windowWidth < 900 && i !== 0 && i % 4 == 0) {
                    output.push(<BannerBIT300x250 key={"bannerPost" + i} />)
                }
                if (this.state.windowWidth > 900 && i !== 0 && i % 4 == 0) {
                    output.push(<BannerBIT790x200 key={"bannerPost" + i} />)
                }


            })
        }
        return output
    }


    render() {
        return (
            <>

                {this.state.isLoader &&
                    <>
                        <Loader />
                    </>
                }


                {!this.state.isLoader &&
                    <>
                        <Header />
                        <UserInfo />

                        <MainContainer>
                            <LeftContainer>

                                {this.state.topicStateData &&
                                    <TopicInfoContainer>

                                        <Info>Temu pokrenuo:
                                            <Link to={`/ucesnik?username=${this.state.topicStateData.creator.split(" ").join("-")}`} target="_blank" rel="noopener noreferrer">
                                                <User>
                                                    {this.state.topicStateData.creator}
                                                </User>
                                            </Link>
                                        </Info>

                                        <IconWrapper>
                                            <ClockFill color="#ffffff" width="24px" height="24px" />
                                            <Info>{this.state.topicStateData.created.humanDate} - {this.state.topicStateData.created.humanTime}</Info>
                                        </IconWrapper>

                                        <IconWrapper>
                                            <EnvelopeFill color="#ffffff" width="24px" height="24px" />
                                            <Info>{this.state.topicStateData.posts.length}</Info>
                                        </IconWrapper>


                                    </TopicInfoContainer>
                                }


                                {this.mapPosts()}
                                {this.context.logedUser.username && !this.state.isNewPost &&
                                    <NewPostButton onClick={this.showAddNewPost}>Dodaj komentar</NewPostButton>}


                                {!this.context.logedUser.username && !this.context.isRegistracija && !this.context.isPravila && !this.context.isPrijava &&
                                    <NewPostButton onClick={this.context.toggleShowRegistracija}>Želiš da se uključiš u diskusiju?<br /><br />Neophodno je da se prvo<br />prijaviš ili registruješ.</NewPostButton>}


                                {this.state.isNewPost &&
                                    <>
                                        {this.state.newQuotedAuthor === null &&
                                            <NewPost>
                                                <TextareaAutosize autoFocus value={this.state.newPostContent} onChange={this.depositNewPostContent} placeholder="Upiši komentar..." />
                                                <RemainingChar>Preostali broj raspoloživih karaktera: {2000-this.state.newPostContent.length}</RemainingChar>
                                            </NewPost>
                                        }

                                        {this.state.newQuotedAuthor !== null &&
                                            <NewPost>
                                                <QuotedContentWrapper>
                                                    <Text>
                                                        {this.state.newQuotedContent}
                                                    </Text>
                                                </QuotedContentWrapper>
                                                <TextareaAutosize autoFocus value={this.state.newPostContent} onChange={this.depositNewPostContent} placeholder={`Upiši odgovor na komentar postavljen od strane korisnika: ${this.state.newQuotedAuthor}`} />
                                                <RemainingChar>Preostali broj raspoloživih karaktera: {2000-this.state.newPostContent.length}</RemainingChar>
                                            </NewPost>
                                        }
                                        {this.state.postLengthIssue &&
                                            <OccupiedNotice>Da bi komentar bio uspešno objavljen, potrebno je da sadrži barem nekoliko reči :-)</OccupiedNotice>
                                        }
                                        <OkCancelWrapper>
                                            <CancelNewPostButton onClick={this.cancelNewPost}>Poništi</CancelNewPostButton>
                                            <OkNewPostButton onClick={this.sendNewPost}>Objavi</OkNewPostButton>
                                        </OkCancelWrapper>
                                    </>}
                                {this.state.windowWidth > 1243 &&
                                    <BannerWideProveriSimptopme />
                                }
                            </LeftContainer>
                            <RightContainer>
                                <Banner300x600ProveriSimptome />
                                <ShareButtonsContainer>
                                    <ShareContainerTitle>
                                        Podeli sa nekim kome će ova tema biti zanimljiva
                </ShareContainerTitle>
                                    <ShareButtonWrapper>
                                        <EmailShareButton url={`https://forum.medik.rs${this.state.path}`}>
                                            Email
                  </EmailShareButton>
                                    </ShareButtonWrapper>
                                    <ShareButtonWrapper>
                                        <FacebookShareButton url={`https://forum.medik.rs${this.state.path}`}>
                                            Facebook
                  </FacebookShareButton>
                                    </ShareButtonWrapper>
                                    <ShareButtonWrapper>
                                        <TwitterShareButton url={`https://forum.medik.rs${this.state.path}`}>
                                            Twitter
                  </TwitterShareButton>
                                    </ShareButtonWrapper>
                                    <ShareButtonWrapper>
                                        <ViberShareButton url={`https://forum.medik.rs${this.state.path}`}>
                                            Viber
                  </ViberShareButton>
                                    </ShareButtonWrapper>
                                    <ShareButtonWrapper>
                                        <WhatsappShareButton url={`https://forum.medik.rs${this.state.path}`}>
                                            Whatsapp
                  </WhatsappShareButton>
                                    </ShareButtonWrapper>
                                    <ShareButtonWrapper>
                                        <LinkedinShareButton url={`https://localhost:3000${this.state.path}`}>
                                            LinkedIn
                  </LinkedinShareButton>
                                    </ShareButtonWrapper>



                                </ShareButtonsContainer>
                                <LastChanges lastChanges={this.state.lastChanges} />
                            </RightContainer>
                        </MainContainer>
                    </>
                }

            </>
        )
    }
}




export default withRouter(Tema)

const RemainingChar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #0068ab;
`

const ShareContainerTitle = styled.div`
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  border-bottom: 4px double #ffffff;
  margin-bottom: 24px;
`

const ShareButtonWrapper = styled.div`
  margin-bottom: 10px;
  border: 2px solid #ffffff;
  border-radius: 15px;
  & > button {
    width: 100%;
  text-align: center;
  font-size: 16px !important;
  padding: 12px !important;
  color: #ffffff !important;
  }
  &:hover{
    transform:scale(1.05);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 1);
    -moz-box-shadow:    0px 0px 5px 0px rgba(255, 255, 255, 1);
    box-shadow:         0px 0px 5px 0px rgba(255, 255, 255, 1);
  }
`

const ShareButtonsContainer = styled.div`
  width: 100%;
  padding: 24px 12px;
  background-color: #50b2d6;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
  -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
  box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);

`

const QuotedContentWrapper = styled.div`
  width: 66%;
  margin: 0 auto 24px auto;
  font-size: 18px;
  font-weight: 600;
  color: #50b2d6;
  font-style: italic;
  text-align: center;
  @media (max-width: 1232px) {
       width: 96%;
      }
`


const User = styled.div`
  font-weight: 600;
  margin-left: 24px;
  color: #ffffff;
  &:hover{
    transform: scale(1.1)
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1232px) {
      margin-top: 18px;
      }
`

const Info = styled.div`
  color: #ffffff;
  font-size: 16px;
  margin-left: 5px;
  display: flex;

`

const TopicInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 12px;
    background: linear-gradient(to right,#6dbab1,#50b2d6);
    padding: 25px;
    -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    @media (max-width: 1232px) {
      flex-direction: column;
      }
`


const NewPostButton = styled.div`
  width: 50%;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  background: linear-gradient(to right,#6dbab1, #50b2d6);
  border: 3px solid #50b2d6;
  border-radius: 15px;
  margin: 0 auto;
  margin-top: 45px;
  text-align: center;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
      -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
      box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    &:hover{
      cursor: pointer;
      -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.5);
      -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.5);
      box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.5);
}
@media (max-width: 1232px) {
       width: 80%;
       margin: 24px auto;
      }
`

const NewPost = styled.div`
    width: 100%;
    margin-top: 50px;
    & > textarea{
      width: 100%;
      min-height: 240px;
      background-color: #ffffff;
      resize: none;
      border: none;
      border-radius: 5px;
      outline: none;
      font-weight:600;
      font-size: 18px;
      padding: 10px 20px;
      color: #59595b;
        &::placeholder {
            color:#a7aaac;
            font-size: 18px;
        }
      }
          @media (max-width: 1232px) {
         width: 98%;
         margin: 0 auto;
         padding: 10px;
        }
`

const OkCancelWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  @media (max-width: 1232px) {
         flex-direction: column-reverse;
        }
`
const CancelNewPostButton = styled.div`
  width: 50%;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  background: linear-gradient(90deg, #ffffff 0%, #AA3939 50%, #AA3939 100%);
  border-radius: 15px;
  margin: 0 auto;
  margin-top: 45px;
  text-align: center;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
      -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
      box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    &:hover{
      cursor: pointer;
      -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.5);
      -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.5);
      box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.5);
}
@media (max-width: 1232px) {
         width: 98%;
         margin: 0 auto;
        }
`

const OkNewPostButton = styled.div`
  width: 50%;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  background: linear-gradient(90deg, #6dbab1 0%, #6dbab1 65%, #ffffff 100%);
  border-radius: 15px;
  margin: 0 auto;
  margin-top: 45px;
  text-align: center;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
      -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
      box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    &:hover{
      cursor: pointer;
      -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.5);
      -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.5);
      box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.5);
}
@media (max-width: 1232px) {
         width: 98%;
         margin: 24px auto;
        }
`


const OccupiedNotice = styled.p`
    color: red;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    padding: 5px 10px;
    background-color: #ffffff;
`