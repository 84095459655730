import React from 'react';
import { AppContext } from './_context/AppContext';
import styled from 'styled-components';
import { Link, withRouter } from "react-router-dom";
import ReactGA from 'react-ga';
import { EnvelopeFill } from 'react-bootstrap-icons';
import Loader from "./Common/Loader";
import putGetAuthor from "../webhooks/getAuthor";
import putGetAuthorsPosts from "../webhooks/getSpecAuthorPosts";
import MainContainer from "./Common/Style/MainContainer";
import LeftContainer from "./Common/Style/LeftContainer";
import RightContainer from "./Common/Style/RightContainer";
import Header from "./Common/Header";
import UserInfo from "./Common/UserInfo";
import Banner300x600ProveriSimptome from "./Common/Banners/Banner300x600ProveriSimptome";
import LastChanges from "./Common/LastChanges";
import MyAvatar from "./Common/MyAvatar";
import logo from "./Common/imgs/favicon.ico"




class Ucesnik extends React.Component {
    static contextType = AppContext;
    state = {
        userData: null,
        topicsCreated: null,
        topicsInvolved: null,
        userRang: null,
        isLoader: true
    }

    componentDidMount() {
        ReactGA.initialize('UA-146952850-1')
        ReactGA.pageview("FORUM"+window.location.pathname + window.location.search)

        const fetchTopics = fetch("https://webhooks.mongodb-stitch.com/api/client/v2.0/app/medikforum-zhmia/service/webhooks/incoming_webhook/getTopics?secret=siFRazADOHVatanmjeTemaizBaze231", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            const result = res.json()
            return result
        })

        const fetchLastChanges = fetch("https://webhooks.mongodb-stitch.com/api/client/v2.0/app/medikforum-zhmia/service/webhooks/incoming_webhook/getLastChanges?secret=koNemapoJMAmaRSIcaleEELae", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            const result = res.json()
            return result
        })

        try {
            Promise.all([fetchTopics, fetchLastChanges]).then((res) => {
                this.context.depositListOfTopics(res[0])
                this.setState({
                    listOfTopics: res[0],
                    lastChanges: res[1]
                })
            });
        } catch (err) {
            console.log(err)
            window.location.reload();
        }
    }




    checkToGetUserData = () => {
        if (this.state.userData === null) {
            const userQuery = this.props.match.params.username
            let userSlug = ""

            if (userQuery.includes("-")) {
                userSlug = userQuery.split("-").join(" ")
            } else {
                userSlug = userQuery
            }

            const data = {
                username: userSlug
            }

            let userData = null
            let userPosts = null
            let topicsCreated = []
            let topicsInvolved = []
            let userRang = null

            putGetAuthor(data, "siFRAODnekioLIKOSamoKarakteRA44321").then((response) => {
                userData = response[0]
                try {
                    putGetAuthorsPosts(data, "asiikeKEFKEOFefkekfppncvbep243.").then((response) => {
                        userPosts = response
                        this.state.listOfTopics.forEach((topic) => {
                            if (topic.creator === userData.username) {
                                topicsCreated.push(topic.topicName)
                            }
                        })

                        userPosts.forEach((post) => {
                            const indx = topicsInvolved.indexOf(post.postTopic)
                            if (indx === -1) {
                                topicsInvolved.push(post.postTopic)
                            }
                        })

                        const topicsInvolvedRemovedCreated = []
                        topicsInvolved.forEach((topic) => {
                            const indx = topicsCreated.indexOf(topic)
                            if (indx === -1) {
                                topicsInvolvedRemovedCreated.push(topic)
                            }
                        })



                        const noPosts = userPosts.length
                        if (noPosts < 5) {
                            userRang = "Novi član"
                        } else if (noPosts > 4) {
                            userRang = "Član"
                        } else if (noPosts > 25) {
                            userRang = "Aktivni član"
                        } else if (noPosts > 50) {
                            userRang = "Ugledni član"
                        } else if (noPosts > 100) {
                            userRang = "Počasni član"
                        } else if (noPosts > 500) {
                            userRang = "Istaknuti član"
                        } else if (noPosts > 1000) {
                            userRang = "Poznati član"
                        }

                        this.context.depositListOfTopics(this.state.listOfTopics)
                        this.setState({
                            userData,
                            topicsCreated,
                            userRang,
                            topicsInvolved: topicsInvolvedRemovedCreated,
                            isLoader: false
                        })
                        // setUserData(userData)
                        // setTopicsCreated(topicsCreated)
                        // setTopicsInvolved(topicsInvolvedRemovedCreated)
                        // setUserRang(userRang)
                        // setIsLoader(false)
                    })

                } catch (err) {
                    window.location.reload()
                }
            })


        }
    }


    mapTopicsCreated = () => {
        if (this.state.topicsCreated !== null) {
            const output = []

            this.state.topicsCreated.forEach((el, i) => {
                const topic = this.state.listOfTopics.filter((topic) => topic.topicName === el)

                const element = <W100Wrapper key={"pokrenutatema" + i}>
                    <Link to={`/tema/${topic[0].topicName.split(" ").join("-")}`} target="_blank" rel="noopener noreferrer" >
                        <TopicItemContainer>
                            <TopicTitleWrapper>
                                <TopicLogo />
                                <TopicTitle>
                                    {topic[0].topicName}
                                </TopicTitle>
                            </TopicTitleWrapper>

                            <DataViewWrapper>
                                <IconWrapper>
                                    <EnvelopeFill color="#50b2d6" width="24px" height="24px" />
                                    <Info>{topic[0].posts.length}</Info>
                                </IconWrapper>

                                {/* <IconWrapper>
            <ClockFill color="#50b2d6" width="24px" height="24px" />
            <Info>22. Mart 2020 - 16:45h poslednji post</Info>
          </IconWrapper> */}

                            </DataViewWrapper>
                        </TopicItemContainer>
                    </Link>
                </W100Wrapper>

                output.push(element)
            })

            return output
        }
    }



    mapTopicsInvolved = () => {
        if (this.state.topicsInvolved !== null) {
            const output = []

            this.state.topicsInvolved.forEach((el, i) => {
                const topic = this.state.listOfTopics.filter((topic) => topic.topicName === el)

                const element = <W100Wrapper key={"temaucesnik" + i}>
                    <Link to={`/tema/${topic[0].topicName.split(" ").join("-")}`} target="_blank" rel="noopener noreferrer" >
                        <TopicItemContainer>
                            <TopicTitleWrapper>
                                <TopicLogo />
                                <TopicTitle>
                                    {topic[0].topicName}
                                </TopicTitle>
                            </TopicTitleWrapper>

                            <DataViewWrapper>
                                <IconWrapper>
                                    <EnvelopeFill color="#50b2d6" width="24px" height="24px" />
                                    <Info>{topic[0].posts.length}</Info>
                                </IconWrapper>

                                {/* <IconWrapper>
            <ClockFill color="#50b2d6" width="24px" height="24px" />
            <Info>22. Mart 2020 - 16:45h poslednji post</Info>
          </IconWrapper> */}

                            </DataViewWrapper>
                        </TopicItemContainer>
                    </Link>
                </W100Wrapper>
                output.push(element)
            })

            return output
        }
    }

    loaderOn = () => {
        this.setState({isLoader: true})
    }

    render() {

        return (
            <>

                {this.state.isLoader &&
                    <>
                        <Loader />
                        {this.checkToGetUserData()}
                    </>}

                {!this.state.isLoader &&
                    <>
                        <Header />
                        <UserInfo />
                        <MainContainer>
                            <LeftContainer>
                                {this.state.userData !== null && this.state.userRang !== null && this.state.topicsCreated !== null && this.state.topicsInvolved !== null &&
                                    <>
                                        <AuthorInfoContainer>
                                            <Title>{this.state.userData.username}</Title>
                                            <AuthorInfoWrapper>
                                                <AvatarWrapper>
                                                    <MyAvatar avatarNo={this.state.userData.avatar} />
                                                </AvatarWrapper>

                                                <UserDataFactsWrapper>
                                                    <UserDataFact><div>Registracija</div><div>{this.state.userData.joinDate.humanDate} - {this.state.userData.joinDate.humanTime}</div></UserDataFact>
                                                    <UserDataFact><div>Rang</div><div>{this.state.userRang}</div></UserDataFact>
                                                    <UserDataFact><div>Pokrenutih tema</div><div>{this.state.topicsCreated.length}</div></UserDataFact>
                                                    <UserDataFact><div>Ostale teme u kojima učestvuje</div><div>{this.state.topicsInvolved.length}</div></UserDataFact>
                                                    <UserDataFact><div>Objavljenih Komentara</div><div>{this.state.userData.posts.length}</div></UserDataFact>
                                                </UserDataFactsWrapper>
                                            </AuthorInfoWrapper>
                                        </AuthorInfoContainer>

                                        {this.state.topicsCreated !== null &&
                                            <>
                                                <TitleTopics>Pokrenute teme:</TitleTopics>
                                                {this.mapTopicsCreated()}
                                            </>
                                        }
                                        {this.state.topicsInvolved !== null &&
                                            <>
                                                <TitleTopics>Ostale teme u kojima učestvuje:</TitleTopics>
                                                {this.mapTopicsInvolved()}
                                            </>
                                        }



                                    </>
                                }

                            </LeftContainer>
                            <RightContainer>
                                <Banner300x600ProveriSimptome />
                                <LastChanges lastChanges={this.state.lastChanges} />
                            </RightContainer>
                        </MainContainer>
                    </>
                }
            </>
            //   }

            // </>
        )
    }
}

export default withRouter(Ucesnik)

const W100Wrapper = styled.div`
  width: 100%;
`

const AuthorInfoContainer = styled.div`
  width: 100%;
  margin: 10px auto;
  padding: 17px 20px 13px;
  border-radius: 5px;
  background: linear-gradient(to right,#6dbab1,#50b2d6);
    -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    
`

const AvatarWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    border-right: 4px double #ffff;
    padding-right: 20px;
    @media (max-width: 1232px) {
        width: 66%;
        border-right: 0;
        padding-bottom: 24px;
        padding-right: 0;
      }
`

const Title = styled.h1`
    width: 100%;
    color: #ffffff;
    text-align: center;
    margin-bottom: 24px;
    padding-bottom: 12px;
    border-bottom: 4px double #ffffff;
`

const TitleTopics = styled.h2`
    width: 100%;
    color: #59595b;
    text-align: center;
    padding-bottom: 12px;
    border-bottom: 4px double #59595b;
    width: 80%;
    margin: 24px auto;
    @media (max-width: 1232px) {
        font-size: 18px;
      }
`


const AuthorInfoWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 1232px) {
        flex-direction: column;
      }
`
const UserDataFactsWrapper = styled.div`
    width: 50%;
    margin: 0 auto;
    @media (max-width: 1232px) {
        width: 100%;
      }
`

const UserDataFact = styled.div`
    color: #ffffff;
    font-size: 18px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px dashed #ffffff;
    margin-bottom: 24px;
    @media (max-width: 1232px) {
        font-size: 16px;
      }
`

const TopicItemContainer = styled.div`
width: 100%;
padding: 10px 20px;
display: flex;
justify-content: space-between;
align-items: center;
background-color: #ffffff;
border: 3px solid #50b2d6;
border-radius: 15px;
margin-bottom: 7.9px;
-webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);
  &:hover{
    cursor: pointer;
    -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.5);
}
`

const TopicTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
  @media (max-width: 1232px) {
        width: 100%;
      }
`

const TopicLogo = styled.img.attrs({
    src: logo
})`
  width: 25px;
  margin-right: 10px;
`
const TopicTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #59595b;
  @media (max-width: 1232px) {
        font-size: 18px;
      }
`

const DataViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Info = styled.p`
  color: #59595b;
  font-size: 16px;
  margin-left: 5px;
  font-weight: 600;
`