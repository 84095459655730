import React from 'react';
import styled from 'styled-components';
import banner from "./baner-wide.jpg";

class BannerWideProveriSimptopme extends React.Component {


  render() {
    return (
      <a href="https://medik.rs/proveri-simptome?utm_source=medik-forum&utm_medium=medik-forum&utm_campaign=medik-forum" target="_blank" rel="noopener noreferrer">
        <ProveriSimptome />
      </a>
    )
  }
}

export default BannerWideProveriSimptopme


const ProveriSimptome = styled.img.attrs({
  src: banner
})`
  width: 100%;
  margin-top: 24px;
  transition: transform 0.1s;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);
  &:hover {
    -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    transform: scale(1.01);
  }
  `