import React from 'react';
import styled from 'styled-components';
import banner from "./bit-300x250.png"

class BannerBIT300x250 extends React.Component {


  render() {
    return (
      <a href="https://www.bgit.rs/?utm_source=medik-forum&utm_medium=medik-forum&utm_campaign=medik-forum" target="_blank">
        <BIT300x250 />
      </a>
    )
  }
}

export default BannerBIT300x250


const BIT300x250 = styled.img.attrs({
  src: banner
})`
  width: 100%;
  margin: 24px auto;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.5);
  `