import React from 'react';
import { AppContext } from "../_context/AppContext";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { EnvelopeFill } from 'react-bootstrap-icons';
import forumLogo from "./imgs/Forum logo.png";
import medikLogo from "./imgs/medikrs.png";
import srchIcon from "./imgs/search-icon.png";
import topicLogo from "./imgs/favicon.ico";
import backImg from "./imgs/Povratak_na_MEDIK.rs.png";



class Header extends React.Component {
    static contextType = AppContext;
    state = {
        searchValue: "",
        eligibleTopics: []
    }

    depositSearchValue = (event) => {
        const searchValue = event.target.value

        const searchValueLC = searchValue.toLowerCase()

        const outputTopics = []


        if (searchValue.length > 2) {
            let topics = this.context.listOfTopics
            topics.sort(function (a, b) {
                return b.posts.length - a.posts.length
            })
            topics.forEach((topic, i) => {
                if (topic.topicName.toLowerCase().includes(searchValueLC)) {
                    const topicElement = <Link key={`searchtema-${i}`} to={`/tema/${topic.topicName.split(" ").join("-")}`} target="_blank" rel="noopener noreferrer">
                        <TopicItemContainer>
                            <TopicTitleWrapper>
                                <TopicLogo />
                                <TopicTitle>
                                    {topic.topicName}
                                </TopicTitle>
                            </TopicTitleWrapper>

                            <DataViewWrapper>
                                <IconWrapper>
                                    <EnvelopeFill color="#50b2d6" width="24px" height="24px" />
                                    <Info>{topic.posts.length}</Info>
                                </IconWrapper>

                            </DataViewWrapper>
                        </TopicItemContainer>
                    </Link>
                    outputTopics.push(topicElement)
                }
            })
        }

        this.setState({
            searchValue: searchValue,
            eligibleTopics: outputTopics
        })
    }

    clearSearch = () => {
        this.setState({
            searchValue: "",
            eligibleTopics: []
        })
    }

    render() {
        return (
            <HeaderContainer>
                <LogoWrapper>
                    <a href="https://forum.medik.rs/">
                        <MedikLogoImg />
                        <ForumLogoImg />
                    </a>
                </LogoWrapper>
                <RightHeaderWrapper>
                    <a href="https://medik.rs/?utm_source=medik-forum&utm_medium=medik-forum&utm_campaign=medik-forum" target="_blank" rel="noopener noreferrer">
                        <BackToMainSite>Povratak na <BackToSiteLogp /></BackToMainSite>
                    </a>
                    {!this.props.isGdpr &&
                        <SearchWrapper>
                            <SearchInput value={this.state.searchValue} onChange={this.depositSearchValue} placeholder="PRETRAGA FORUMA" />
                            <SearchIcon />
                        </SearchWrapper>
                    }
                </RightHeaderWrapper>
                {this.state.eligibleTopics.length > 0 &&
                    < SearchResultsWrapper >
                        <SearchResultTitle>Rezultati pretrage:</SearchResultTitle>
                        <CloseIcon onClick={this.clearSearch}>X</CloseIcon>
                        {this.state.eligibleTopics}
                    </SearchResultsWrapper>
                }
            </HeaderContainer>
        )
    }
}

export default Header

const RightHeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    @media (max-width: 992px) {
        height: 42%;
        align-items: center;
      }
`

const BackToSiteLogp = styled.img.attrs({
    src: backImg
})`
width: 124px;
height: auto;
margin-left: 12px;
`
const CloseIcon = styled.div`
    position: absolute;
    right: 24px;
    top: 12px; 
    padding: 5px 10px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 800;
    border: 2px solid #ffffff;
    &:hover{
        cursor: pointer;
        background-color: #ffffff;
        color: #59595b;
        border-color: #59595b;
    }
`


const TopicItemContainer = styled.div`
width: 100%;
padding: 10px 20px;
display: flex;
justify-content: space-between;
align-items: center;
background-color: #ffffff;
border: 3px solid #50b2d6;
border-radius: 15px;
margin-bottom: 7.9px;
-webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);
  &:hover{
    cursor: pointer;
    -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.5);
}
`

const TopicTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
`

const TopicLogo = styled.img.attrs({
    src: topicLogo
})`
  width: 25px;
  margin-right: 10px;
`
const TopicTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #59595b;
  @media (max-width: 1232px) {
        font-size: 18px;
      }
`

const DataViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Info = styled.p`
  color: #59595b;
  font-size: 16px;
  margin-left: 5px;
  font-weight: 600;
`


const SearchResultTitle = styled.div`
    color: #59595b;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin-bottom: 24px;
    padding-bottom: 12px;
    border-bottom: 3px double #ffffff;
`

const SearchResultsWrapper = styled.div`
    width: 100%;
    position: absolute;
    right: 0;
    top: 120px;
    background: linear-gradient(to right,#6dbab1,#50b2d6);
    padding: 25px;
    border-radius: 15px;
    -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.3);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.3);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.3);
    @media (max-width: 992px) {
       top: 270px;
      }
`

const HeaderContainer = styled.header`
    position: relative;
    width:  1232px;
    height: 114px;
    margin: 0 auto;
    padding: 17px 20px 13px;
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-box-shadow: 0 0 12px 0 rgba(50,50,50,0.2);
    box-shadow: 0 0 12px 0 rgba(50,50,50,0.2);
    @media (max-width: 1232px) {
        width: 100vw;
      }
      @media (max-width: 992px) {
       flex-direction: column;
       height: 275px;
      }
`
const SearchWrapper = styled.div`
    width: 291px;
    height: 41px;
    border: 1px solid #a7aaac;
    padding: 10px 15px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const SearchInput = styled.input`
    border: none;
    background: transparent;
    flex: 1;
    outline: none;
    font-weight:600;
    font-size: 18px;
    color: #59595b;
    &::placeholder {
        color:#a7aaac;
        font-size: 14px;
    }
`
const SearchIcon = styled.img.attrs({
    src: srchIcon
})
    `
width: 21px;
height: 21px;
`
const LogoWrapper = styled.div`
    position: relative;
`


const ForumLogoImg = styled.img.attrs({
    src: forumLogo
})
    `
width: 266px;
height: auto;

`

const MedikLogoImg = styled.img.attrs({
    src: medikLogo
})
    `
position: absolute;
width: 266px;
height: auto;
transition: transform 0.2s;
&:hover {
    transform: scale(1.1);
}
`

const BackToMainSite = styled.div`
      font-size: 16px;
  /* background: -webkit-linear-gradient (#6dbab1, #50b2d6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color:#a7aaac;
  font-weight: 600;
  display: flex;
  align-items: center;
  &:hover >img{
      transform: scale(1.1)
  }


/* 
  background: -webkit-linear-gradient(to right,#6dbab1,#50b2d6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
`