import React from 'react';
import { AppContext } from "../_context/AppContext";
import { Link } from "react-router-dom";
import styled from 'styled-components';


class LastChanges extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props)
        this.state = {
            lastPosts: null,
            lastTopics: null
        }
    }
componentDidMount(){
    if (this.props.lastChanges !== null){
        this.mapLastTopics(this.props.lastChanges[0])
        this.mapLastPosts(this.props.lastChanges[1])
    }
}

    componentWillReceiveProps(nextProps) {
        if (this.props.lastChanges !== nextProps.lastChanges) {
            this.mapLastTopics(nextProps.lastChanges[0])
            this.mapLastPosts(nextProps.lastChanges[1])
        }
    }

    mapLastPosts = (data) => {
        const output = data.lastFive.map((lastPost, i) => {
            if (lastPost) {
                const tema = lastPost.split("=post=")[0]
                const post = lastPost.split("=post=")[1]
                return <Link key={`poslednjatema-${i}`} to={`/tema/${tema.split(" ").join("-")}`} target="_blank" rel="noopener noreferrer">
                    <LastChangesItem >
                        {post.slice(0, 140)}...
                            </LastChangesItem>
                </Link>
            }
        })
        this.setState({ lastPosts: output })
    }

    mapLastTopics = (data) => {
        const output = data.lastFive.map((lastTopic, i) => {
            if (lastTopic) {
                return <Link key={`poslednjatema-${i}`} to={`/tema/${lastTopic.split(" ").join("-")}`} target="_blank" rel="noopener noreferrer">
                    <LastChangesItem >
                        {lastTopic}
                    </LastChangesItem>
                </Link>
            }
        })
        this.setState({ lastTopics: output })
    }


    render() {

        return (
            <LastChangesContainer>
                <LastChangesWrapper>
                    <LastChangesTitle>
                        Najnovije poruke:
                    </LastChangesTitle>
                    {this.state.lastPosts}
                </LastChangesWrapper>
                <LastChangesWrapper>
                    <LastChangesTitle>
                        Najnovije teme:
                    </LastChangesTitle>
                    {this.state.lastTopics}
                </LastChangesWrapper>
            </LastChangesContainer>
        )
    }
}

export default LastChanges

const LastChangesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`
const LastChangesWrapper = styled.div`
    padding:10px;
    -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    background-color: #ffffff;
    border-radius: 10px;
    border: 3px solid #50b2d6;
    margin-bottom: 24px;
`
const LastChangesTitle = styled.div`
    color: #59595b;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-bottom: 3px solid #50b2d6;
    padding-bottom: 5px;
`

const LastChangesItem = styled.div`
    color: #59595b;
    font-size: 16px;
    text-align: center;
    border-bottom: 4px double #6dbab1;
    padding-bottom: 5px;
    padding-top: 5px;
    &:hover{
        color: #ffffff;
        background: linear-gradient(to right,#6dbab1,#50b2d6);
        cursor: pointer;
    }
`