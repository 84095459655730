import React from 'react';
import styled from 'styled-components';
import bannerImg from "./banner300x600.png"


class Banner300x600ProveriSimptome extends React.Component {


  render() {
    return (
      <a href="https://medik.rs/proveri-simptome?utm_source=medik-forum&utm_medium=medik-forum&utm_campaign=medik-forum" target="_blank" rel="noopener noreferrer">
        <ProveriSimptome />
      </a>
    )
  }
}

export default Banner300x600ProveriSimptome


const ProveriSimptome = styled.img.attrs({
  src: bannerImg
})`
  width: 100%;
  transition: transform 0.1s;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.1);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.1);
  &:hover {
    -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    -moz-box-shadow:    7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    box-shadow:         7px 7px 5px 0px rgba(50, 50, 50, 0.5);
    transform: scale(1.01);
  }
  `