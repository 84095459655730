import React from 'react';
import Switch from "react-switch";
import {AppContext} from "../_context/AppContext";
import styled from 'styled-components';


class NotificationSwitch extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props)
    }
    state = {
        checked: this.props.isChecked,
    }

    handleChange = (checked) => {
        this.props.changeTopicsNotifications(this.props.topic, checked)
        this.setState({checked})
    }


    render() {

        return (
            <NotificationItemWrapper>
                <NotificationTitle>
                    {this.props.topic}
                </NotificationTitle>
                <Switch onChange={this.handleChange} checked={this.state.checked}/>
            </NotificationItemWrapper>

        )
    }
}



export default NotificationSwitch

const NotificationItemWrapper = styled.label`
    width: 50%;
    margin: 24px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1232px) {
        width: 100%;
      }
    
`
const NotificationTitle = styled.span`
    color: #ffffff;
    font-size: 18px;
`
